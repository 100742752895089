import React, { useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Header from "../header-footer/header";
import Footer from "../header-footer/footer";
import "../../css/style.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { API } from "../../services/ApiClient";
import { useForm } from "react-hook-form";
import ContactUsComponent from "../../components/ContactUsComponent";
import Swal from "sweetalert2";
import OwlCarousel from 'react-owl-carousel2';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import DoesQa from "../../assets/images/does-qa.png";
import pancakeswap from "../../assets/images/uniswap.png";
import uniswap from "../../assets/images/pancakeswap.png";
import crypto from "../../assets/images/crypto.png";
import TokenMetrics from "../../assets/images/token-metrics-main.png";

import Earn from "../../assets/images/earn/earn-1.png";

// import currency from "../../assets/currency1.jpg"
import currency from "../../assets/sociallocket.png";

import Blockchain from "../../assets/images/partners/future-blockchain-summit.jpeg";
import CryptoExpo from "../../assets/images/partners/crypto-expo.png";
import YahooFinance from "../../assets/images/partners/yahoo-finance.jpeg";
import CyberScope from "../../assets/images/partners/cyberscope.jpeg";
import Vincitore from "../../assets/images/partners/vincitore.png";
import Binghatti from "../../assets/images/partners/binghatti.jpeg";
import Danube from "../../assets/images/partners/danube.png";


const schema = yup.object({
  email: yup.string().email().required("Email is a required"),
  name: yup.string().required("Name is a required"),
  public_key: yup.string().required("Contact Number is a required"),
  amount: yup
    .number()
    .typeError("Message is a required")
    .positive("Message is a required"),
});

const CountdownPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [success, setSuccess] = useState(false);
  const onSubmit = async (data, event) => {
    event.preventDefault();
    API.post(process.env.REACT_APP_URL + "api/v1/contracts", data)
      .then((response) => {
        setSuccess(true);
        console.log("response: ", response);
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Your form has been submitted.",
        });
        reset();
      })
      .catch((error) => {
        console.error("error----", error);
        if (error && error.response)
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error?.response?.data?.message,
          });
      });
  };
  const options = {
    items: 3,
    nav: false,
    dots:true,
    rewind: true,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    },
  };

  // const { tokenBalance } = useContext(PreSaleContext);

  return (
    <>
      <Header />


      <section
        className="go-live"
        style={{
          background: "url(assests/images/about-bg-pettern.png) #f1f1f1",
          backgroundPosition: "left center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6">
              <ContactUsComponent duration={86400 * 5} />
            </div>
            <div className="col-lg-6">
              <div className="login-box-2">
                <div className="text-center">
                  <h2 className="mt-3"><strong>Contact Us</strong></h2>
                  <p className="mb-5 m-auto">
                    We're a few clicks away! 
                  </p>
                </div>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="count-form"
                  style={{ display: "inherit" }}
                >
                  <div className="user-box">
                    <div class="position-relative">
                      <input
                        type="text"
                        name="name"
                        {...register("name")}
                        required=""
                      />
                      <label>Full Name*</label>
                    </div>
                    {errors.name?.message ? (
                      <div className="alert alert-danger" role="alert">
                        {errors?.name?.message}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="user-box">
                    <div class="position-relative">
                      <input
                        type="text"
                        name="email"
                        required=""
                        {...register("email")}
                      />
                      <label>Email Id*</label>
                    </div>
                    {errors.email?.message ? (
                      <div className="alert alert-danger" role="alert">
                        {errors?.email?.message}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="user-box">
                    <div class="position-relative">
                      <input
                        type="text"
                        name="public_key"
                        {...register("public_key")}
                        required=""
                      />
                      <label>Contact Number*</label>
                    </div>
                    {errors.public_key?.message ? (
                      <div className="alert alert-danger" role="alert">
                        {errors?.public_key?.message}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="user-box">
                    <div class="position-relative">
                      <input
                        type="text"
                        name="amount"
                        // {...register("message", { valueAsNumber: true })}
                        {...register("message")}
                        required=""
                      />
                      <label>Message*</label>
                    </div>
                    {errors.amount?.message ? (
                      <div className="alert alert-danger" role="alert">
                        {errors?.amount?.message}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="text-center ">
                    <button type="submit">
                      <span />
                      <span />
                      <span />
                      <span />
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our-partner pb-0">
        <div className="container">
          <div className="row">
              <div className="col-lg-12">
              {/* <h1 className="bg-txt-black text-center mb-5"><strong>Some of our many <span className="bg-blu">Partners</span></strong></h1> */}
              <h1 className="bg-txt-black text-center mb-5"><strong>Brands already earning with <span className="bg-blu">Sociallocket</span></strong></h1>
                <OwlCarousel options={options}>
                  <div className="item">
                    <a href="https://app.sociallocket.com/register">
                      <img src={YahooFinance} className="img-fluid" alt="client-logos" width={200}  />
                    </a>
                  </div> 
                  <div className="item">
                    <a href="https://app.sociallocket.com/register">
                      <img src={Binghatti} className="img-fluid" alt="client-logos" width={200}  />
                    </a>
                  </div>
                  <div className="item">
                    <a href="https://app.sociallocket.com/register">
                      <img src={Danube} className="img-fluid" alt="client-logos" width={200}  />
                    </a>
                  </div>
                  <div className="item">
                    <a href="https://app.sociallocket.com/register">
                      <img src={Vincitore} className="img-fluid" alt="client-logos" width={200}  />
                    </a>
                  </div>
                  <div className="item">
                    <a href="https://app.sociallocket.com/register">
                      <img src={Blockchain} className="img-fluid" alt="client-logos" width={200}  />
                    </a>
                  </div>
                  <div className="item">
                    <a href="https://app.sociallocket.com/register">
                      <img src={CryptoExpo} className="img-fluid" alt="client-logos" width={200}  />
                    </a>
                  </div>
                  {/* <div className="item">
                      <img src={CyberScope} className="img-fluid" alt="client-logos" width={200}  />
                  </div> */}
                  {/* <div className="item">
                      <img src={DoesQa} className="img-fluid" alt="client-logos" width={200}  />
                  </div> */}

                  <div className="item">
                    <a href="https://app.sociallocket.com/register">
                      <img src={YahooFinance} className="img-fluid" alt="client-logos" width={200}  />
                    </a>
                  </div> 
                  <div className="item">
                    <a href="https://app.sociallocket.com/register">
                      <img src={Binghatti} className="img-fluid" alt="client-logos" width={200}  />
                    </a>
                  </div>
                  <div className="item">
                    <a href="https://app.sociallocket.com/register">
                      <img src={Danube} className="img-fluid" alt="client-logos" width={200}  />
                    </a>
                  </div>
                  <div className="item">
                    <a href="https://app.sociallocket.com/register">
                      <img src={Vincitore} className="img-fluid" alt="client-logos" width={200}  />
                    </a>
                  </div>
                  <div className="item">
                    <a href="https://app.sociallocket.com/register">
                      <img src={Blockchain} className="img-fluid" alt="client-logos" width={200}  />
                    </a>
                  </div>
                  <div className="item">
                    <a href="https://app.sociallocket.com/register">
                      <img src={CryptoExpo} className="img-fluid" alt="client-logos" width={200}  />
                    </a>
                  </div>
                  {/* <div className="item">
                      <img src={CyberScope} className="img-fluid" alt="client-logos" width={200}  />
                  </div> */}
                </OwlCarousel>
              </div>
          </div>
        </div>
      </section>

      <section className="about-us bg-blue">
        <div className="container">
          <div className="row align-items-center flex-row-reverse">
          <div className="col-lg-6 col-md-6">
              <div className="text-center">
                <a href="https://app.sociallocket.com/register">
                  <img src={Earn} className="img-fluid" alt="tenants" />
                </a>
              </div>
            </div>
            
            <div className="col-lg-6 col-md-6">
              <div className="connect-content mb-5">
                <h2 className="white mb-4"><strong className="bg-txt-black mb-4">Earn</strong> additional income with your <strong className="bg-txt-black mb-4">property</strong></h2>
                <div className="mt-5">
                  <div className="theme-button">
                    <a href="https://app.sociallocket.com/register"><strong>Find out more</strong></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <Footer />
      {/* bootstrap jquery */}
      {/* bootstrap js */}
      {/* owl js */}
      {/* custom js */}
    </>
  );
};

export default CountdownPage;