import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Header from "./header-footer/header";
import Footer from "./header-footer/footer";
import { useForm } from "react-hook-form";
import Aboutus from "../assets/images/about-us.png";
import crypto from "../assets/images/crypto.png";
import Laptop from "../assets/images/laptop.png";
import pancakeswap from "../assets/images/uniswap.png";
import uniswap from "../assets/images/pancakeswap.png";
import Faq from "../assets/images/faq.png";
// import team from "../assets/images/team.jpg";
//import Hamid from "../assets/images/Hamid_Rahman.jpg";
import Jermaine from "../assets/images/Jermaine_Anderson.jpg";
import Rishabh from "../assets/images/Rishabh_Rawat.jpg";
import "../css/style.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { API } from "../services/ApiClient";
import Swal from "sweetalert2";
import OwlCarousel from 'react-owl-carousel2';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import DoesQa from "../assets/images/does-qa.png";
import Blockchain from "../assets/images/future-blockchain-summit.jpeg";
import CryptoExpo from "../assets/images/crypto-expo.png";

import Earn from "../assets/images/earn/earn-1.png";


const schema = yup.object({
  email: yup.string().email().required("Email is a required field"),
  name: yup.string().required("Name is a required field"),
  public_key: yup.string().required("Public key is a required field"),
  amount: yup
    .number()
    .typeError("Amount is a required field")
    .positive("Amount must be a positive number"),
});
const Landing = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [success, setSuccess] = useState(false);

  const onSubmit = async (data, event) => {
    event.preventDefault();
    API.post(process.env.REACT_APP_URL + "api/v1/contracts", data)
      .then((response) => {
        setSuccess(true);
        if (response) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Your form has been submitted.",
          });
          reset();
        }
      })
      .catch((error) => {
        if (error && error.response)
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error?.response?.data?.message,
          });
      });
  };
  const options = {
    items: 3,
    nav: false,
    dots:true,
    rewind: true,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    },
  };
  return (
    <>
      <Header />
      <section className="blog-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 banner-content">
              <h1 className="white mb-4">Quick <span className="bg-blu">Questions</span>, Quick <span className="bg-blu">Answers</span></h1>
              {/* <h4 className="bg-blu mb-2">Own From 1% to 100% of Your Next Asset</h4> */}
              <p className="white mb-4">
              <strong>Sociallocket</strong>, the <strong>property portal</strong> changing<strong> Real Estate </strong> forever!
              </p>
              <div className="mt-lg-5">
                <div className="theme-button">
                  {/* <a href="https://sociallocket.com/coming-soon">Login</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faq">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="faq-content mb-5">
                <div className="line-tag">
                  <h5 className="bg-blu mb-3">Frequently asked questions</h5>
                  <span>&nbsp;</span>
                </div>
                <h2>Do you have a Question? Questions &amp; Answers.</h2>
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button collapsed justify-content-end flex-row-reverse"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Can Sociallocket help me purchase real estate assets?
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Yes. Sociallocket at its core has been designed and built to 
                        aid and facilitate the purchase of any real estate asset regardless
                        of the location.
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed justify-content-end flex-row-reverse"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="true"
                        aria-controls="collapseTwo"
                      >
                        Can Sociallocket manage large development projects?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Yes. That is exactly what Sociallocket has been designed for!
                        It is designed and built to manage large development projects at scale.
                        This means, no more worrying about what unit are still available, let,
                        sold or under offer. 
                        You will be able to keep track of the entire project (Apartment Block, HMO and Housing Association). 
                        Don't take our word for it. Create an account and <a href="https://app.sociallocket.com/register"><strong>Create an Account</strong></a>. 
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed justify-content-end flex-row-reverse"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="true"
                        aria-controls="collapseThree"
                      >
                        Can I use my email ID to create multiple accounts?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Unfortunately, Sociallocket does not allow an email ID to be used more
                        than once.
                        However, you can connect accounts together. This is one of many benifits
                        the Sociallocket platform offers. Create an account and <a href="https://app.sociallocket.com/register"><strong>Get Started</strong></a>. 
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                      <button
                        className="accordion-button collapsed justify-content-end flex-row-reverse"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="true"
                        aria-controls="collapseFour"
                      >
                        Can I manage my property's using the Sociallocket platform?
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Yes. Sociallocket at its core has been designed and built to manage
                        your properties. With easy to use apps and tools, you can manage
                        your property's and tenants.
                        No matter how many property's you own or manage, Sociallocket will 
                        help you keep structure in your day to day life.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFive">
                      <button
                        className="accordion-button collapsed justify-content-end flex-row-reverse"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="true"
                        aria-controls="collapseFive"
                      >
                        Can I manage my tenants and or residents using the Sociallocket platform?
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Yes. Sociallocket has been proven to be effective when managing
                        your tenants and or residents. 
                        With it's in-built CRM (Customer Relationship Management) tools and apps,
                        Sociallocket will save you time and stress when managing clients, portfolios and or tenants.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSix">
                      <button
                        className="accordion-button collapsed justify-content-end flex-row-reverse"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSix"
                        aria-expanded="false"
                        aria-controls="collapseSix"
                      >
                        Can Sociallocket help me manage my clients property portfolios?
                      </button>
                    </h2>
                    <div
                      id="collapseSix"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSix"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Yes. Sociallocket allows you to manage your clients portfolios at ease.
                        It is designed to make life easier. Thats not all! You can even share 
                        the progress of your clients portfolios with your clients allowing them 
                        to be constantly be in tune with the progress you've made.         
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSeven">
                      <button
                        className="accordion-button collapsed justify-content-end flex-row-reverse"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSeven"
                        aria-expanded="false"
                        aria-controls="collapseSeven"
                      >
                        What payment methods does Sociallocket accept?
                      </button>
                    </h2>
                    <div
                      id="collapseSeven"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSeven"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Aiming to be diverse, Sociallocket will accept payments of all kinds.
                        That's right. You can even purchase your real estate asset using cryptocurrency,
                        paypal and other payment methods.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingEight">
                      <button
                        className="accordion-button collapsed justify-content-end flex-row-reverse"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseEight"
                        aria-expanded="false"
                        aria-controls="collapseEight"
                      >
                        How secure is Sociallocket?
                      </button>
                    </h2>
                    <div
                      id="collapseEight"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingEight"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Here at Sociallocket, security is number one followed by speed and transparency.
                        In using the most secure and reliable technology combined with best practices and secure
                        protocols, Sociallocket is SECURE!
                      </div>
                    </div>
                  </div>
                  
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingNine">
                      <button
                        className="accordion-button collapsed justify-content-end flex-row-reverse"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseNine"
                        aria-expanded="false"
                        aria-controls="collapseNine"
                      >
                        Can I advertise for FREE?
                      </button>
                    </h2>
                    <div
                      id="collapseNine"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingNine"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Yes. Sociallocket wants to do things different. 
                        That is one of the reasons we let you advertise for FREE!
                        Create an account and <a href="https://app.sociallocket.com/register"><strong>Get Started</strong></a>.
                      </div>
                    </div>
                  </div>
                  
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTen">
                      <button
                        className="accordion-button collapsed justify-content-end flex-row-reverse"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTen"
                        aria-expanded="false"
                        aria-controls="collapseTen"
                      >
                        Can I manage propertys for FREE?
                      </button>
                    </h2>
                    <div
                      id="collapseTen"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTen"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        Yes. Sociallocket allows you to manage your entire portfolio for FREE!
                        Join other agenecys, agents and landlords by getting started today.
                        <a href="https://app.sociallocket.com/register"><strong>Create an account</strong></a>.
                      </div>
                    </div>
                  </div>

                  {/* <div className="accordion-item">
                    <h2 className="accordion-header" id="headingNine">
                      <button
                        className="accordion-button collapsed justify-content-end flex-row-reverse"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseNine"
                        aria-expanded="false"
                        aria-controls="collapseNine"
                      >
                        Is Sociallocket multi-chain?
                      </button>
                    </h2>
                    <div
                      id="collapseNine"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingNine"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        As we aim to remain diverse. Sociallocket will be multi-chain,
                        giving users the option to use the chain of there choice.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTen">
                      <button
                        className="accordion-button collapsed justify-content-end flex-row-reverse"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTen"
                        aria-expanded="false"
                        aria-controls="collapseTen"
                      >
                        What is a Public Key?
                      </button>
                    </h2>
                    <div
                      id="collapseTen"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTen"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                      A public key is a numerical code used to encrypt data. A public key allows users to send and receive cryptocurrencies of different types. While it is possible for anyone to securely send transactions, 
                      you’ll need a private key to help unlock them as part of proving you are the owner.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingEleven">
                      <button
                        className="accordion-button collapsed justify-content-end flex-row-reverse"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseEleven"
                        aria-expanded="false"
                        aria-controls="collapseEleven"
                      >
                        What is a Private Key?
                      </button>
                    </h2>
                    <div
                      id="collapseEleven"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingEleven"
                      data-bs-parent="#accordionExample"
                    >
                    <div className="accordion-body">
                      A private key is a numerical code used to encrypt data. Similar to a password, whilst making transactions using various types of cryptocurrencies, a private key works hand in hand with the public key. 
                      It securely authorises transactions and proves ownership of a blockchain asset.                      </div>
                    </div>
                  </div> 

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwelve">
                      <button
                        className="accordion-button collapsed justify-content-end flex-row-reverse"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwelve"
                        aria-expanded="false"
                        aria-controls="collapseTwelve"
                      >
                        Can Sociallocket Assist with Participating in the ICO?
                      </button>
                    </h2>
                    <div
                      id="collapseTwelve"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwelve"
                      data-bs-parent="#accordionExample"
                    >
                    <div className="accordion-body">
                    YES. At Sociallocket, we pride on putting our community first. Please join our <a href="https://t.me/+oIfE_V72MTFkNTZks">
                      <span>Telegram</span>
                      <i className="fa-brands fa-telegram" />
                    </a> where either the CEO (Jermaine Anderson) or another member of the team will assist you.
                    </div>
                    </div>
                  </div>  */}

                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="text-center mt-3">
                <a href="https://app.sociallocket.com/register">
                  <img src={Faq} className="img-fluid" alt="faq" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="Connect-with">
        <div className="container">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-lg-6 col-md-6">
              <div className="connect-content mb-5">
                <h2 className="white mb-4">Have You Created An Account?</h2>
                <h4 className="white mb-4">
                <strong>Sign up</strong> to <strong>Sociallocket</strong> for <strong>FREE</strong>. We made it <strong>easy</strong>.
                </h4>
                <div className="mt-5">
                  <div className="theme-button">
                    <a href="/pricing"><strong>Sign up Free</strong></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="text-center">
                <a href="https://app.sociallocket.com/register">
                  <img src={Laptop} className="img-fluid" alt="laptop" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-us">
        <div className="container">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-lg-6 col-md-6">
              <div className="text-center">
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/YYwGN2OautA"></iframe>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="connect-content mb-5">
                <h2 className="bg-txt-black  mb-4">The Power Of <span className="bg-blu">Sociallocket</span></h2>
                <h4 className="bg-txt-black  mb-4">
                See how <strong>Sociallocket</strong> can change <strong>your future forever!</strong>
                </h4>
                <div className="mt-5">
                  <div className="theme-button">
                    <a href="https://app.sociallocket.com/register"><strong>Post Ad</strong></a>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </section>

      {/* <section className="about-us bg-blue">
        <div className="container">
          <div className="row align-items-center flex-row-reverse">
          <div className="col-lg-6 col-md-6">
              <div className="text-center">
                <a href="https://app.sociallocket.com/register">
                  <img src={Earn} className="img-fluid" alt="tenants" />
                </a>
              </div>
            </div>
            
            <div className="col-lg-6 col-md-6">
              <div className="connect-content mb-5">
                <h2 className="white mb-4"><strong className="bg-txt-black mb-4">Earn</strong> additional income with your <strong className="bg-txt-black mb-4">Property</strong></h2>
                <div className="mt-5">
                  <div className="theme-button">
                    <a href="https://app.sociallocket.com/register"><strong>Find out more</strong></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className="map-form">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="login-box">
                <div className="text-center">
                  
                  <h2>Join Our Presale</h2>
                  <h4 className="white mb-3 m-auto">
                    <strong >Limited Availablity</strong>, so lets get you signed up before you miss your spot
                  </h4>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="user-box">
                    <div class="position-relative">
                      <input
                        type="text"
                        name="name"
                        {...register("name")}
                        required=""
                      />
                      <label>Full Name*</label>
                    </div>
                    {errors.name?.message ? (
                      <div className="alert alert-danger" role="alert">
                        {errors?.name?.message}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="user-box">
                    <div class="position-relative">
                      <input
                        type="email"
                        name="email"
                        {...register("email")}
                        required=""
                      />
                      <label>Email Id*</label>
                    </div>
                    {errors.email?.message ? (
                      <div className="alert alert-danger" role="alert">
                        {errors?.email?.message}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="user-box">
                    <div class="position-relative">
                      <input
                        type="text"
                        name="public_key"
                        {...register("public_key")}
                        required=""
                      />
                      <label>Public key*</label>
                    </div>
                    {errors.public_key?.message ? (
                      <div className="alert alert-danger" role="alert">
                        {errors?.public_key?.message}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="user-box">
                    <div class="position-relative">
                      <input
                        type="number"
                        name="amount"
                        {...register("amount", {
                          valueAsNumber: true,
                        })}
                        required=""
                      />
                      <label>Amount*</label>
                    </div>
                    {errors.amount?.message ? (
                      <div className="alert alert-danger" role="alert">
                        {errors?.amount?.message}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="text-center">
                    <button type="submit">
                      <span />
                      <span />
                      <span />
                      <span />
                      Pre Sale
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <Footer />
      {/* bootstrap jquery */}
      {/* bootstrap js */}
      {/* owl js */}
      {/* custom js */}
    </>
  );
};

export default Landing;