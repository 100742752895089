import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Header from "../../header-footer/header";
import Footer from "../../header-footer/footer";
import { useForm } from "react-hook-form";
import "../../../css/style.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { API } from "../../../services/ApiClient";
import Swal from "sweetalert2";
import OwlCarousel from 'react-owl-carousel2';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Keys from "../../../assets/images/additional/keys-gold.png";
import InflationImage from "../../../assets/images/inflation.png";
import AnimatedLogo from "../../../assets/images/blue-logo-white-bg.gif";
//import Faq from "../../../assets/images/faq.png";

const schema = yup.object({
  email: yup.string().email().required("Email is a required field"),
  name: yup.string().required("Name is a required field"),
  public_key: yup.string().required("Public key is a required field"),
  amount: yup
    .number()
    .typeError("Amount is a required field")
    .positive("Amount must be a positive number"),
});
const BlogSellingYourPropertyInLondon = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [success, setSuccess] = useState(false);

  const onSubmit = async (data, event) => {
    event.preventDefault();
    API.post(process.env.REACT_APP_URL + "api/v1/contracts", data)
      .then((response) => {
        setSuccess(true);
        if (response) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Your form has been submitted.",
          });
          reset();
        }
      })
      .catch((error) => {
        if (error && error.response)
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error?.response?.data?.message,
          });
      });
  };
  const options = {
    items: 3,
    nav: false,
    dots:true,
    rewind: true,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    },
  };
  return (
    <>
      <Header />

        <section className="our-team">
          <div className="container">
          <div className="row">
            <div className="banner-size banner-content flex justify-content-center align-items-center m-auto">
              {/* <h1 className="white mb-4">Manage your Property & <span className="col-lg-10 bg-blu mb-2">Tenants</span> <span className="col-lg-10 bg-blu mb-2">without</span> the <span className="col-lg-10 bg-blu mb-2">Stress</span></h1> */}
              {/* <h4 className="col-lg-10 bg-blu mb-2">All the Tools you need to Manage Your Property Today!</h4> */}
                <h1 className="black mb-4 text-center">
                Planning to Sell{" "}
                <span className="col-lg-10 bg-blu mb-2">Your London</span>{" "}
                <span className="col-lg-10 bg-blu mb-2">Property?</span>              
                </h1>

              <div className="mt-lg-5 d-flex align-items-center justify-content-center">
                <div className="theme-button">
                  <a href="https://app.sociallocket.com/register">
                    <strong>Sign Up Free</strong>
                  </a>
                </div>
              </div>
            </div>
          </div>
          </div>
        </section>

      <section className="about-us">
        <div className="container">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-lg-6 col-md-6">
            <div className="text-center mt-3">
                <a href="https://app.sociallocket.com/register">
                    <img src={AnimatedLogo} className="img-fluid" alt="AnimatedLogo" />
                </a>
              </div>            
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="connect-content mb-5">
              <h5 className="bg-txt-black  mb-4">Date - Jermaine Anderson</h5>

                    <p className="bg-txt-black  mb-4">
                    In London, there’s so much choice for food, bars and restaurants much less places to live.
                    Have you been asking ChatGPT for help?
                    As the importance of standing out against local competition, real estate agents, landlords and developers across London, the capital of the United Kingdom are on the lookout for new, cheaper and more creative ways to reach their audience (renter, buyers, sellers and even landlords).
                    </p>

                    <p className="bg-txt-black  mb-4">
                    As inflation has causes many to shut up shop and others constantly battling with property portals like Rightmove, Zoopla and On The Market, real estate agents are finding it hard with monthly spending coming in at over a £1,000.  Not wanting to deviate from the rule of thumb and only spend 10% of their revenue to advertising, both off and online, cheaper and more effective alternatives are more imperative now more than ever.
                    </p>

                    <p className="bg-txt-black  mb-4">
                    Striving to make life easier by reducing costs, time and stress, Sociallocket have come up with a solution. 
                    With <strong>Sociallocket</strong> being a cheaper alternative to property portal and platforms like Zoopla, Rightmove, SpareRoom and On The Market to name a few, we are determine to bring about a change.
                    </p>

                    <p className="bg-txt-black  mb-4">
                    <strong>Sociallocket</strong> is a unique property portal designed to streamline the acquisitions and rentals of real estate assets (Apartments, Houses, Bungalows, Commercial Buildings, Rooms and many more).
                    <strong> Sociallocket</strong> is designed to connect renters (tenants) with real estate agents and landlords as part of bringing the joy back into our homes.
                    </p>

                    <p className="bg-txt-black  mb-4">
                    With WhatsApp like features and a Shopify style approach, Sociallocket is paving the way and removing stress from property investing and renting.
                    </p>

                    <p className="bg-txt-black  mb-4">
                    We are far from getting started but we take pride in the Sociallocket platform as it’s making things easier for agents, landlords, renters and even real estate (property) developers by providing the tools and apps they need.
                    </p>

                    <p className="bg-txt-black  mb-4">
                    The platforms features consist of:
                    </p>

                    <p className="bg-txt-black  mb-4">
                        <li><strong>Free Property Advertisement</strong></li>
                        <li>Instant Chat (Both private and public)</li>
                        <li>Property Management</li>
                        <li>Customer Relationship Management</li>

                        <li>Portfolio Tracking</li>
                        <li>Payment Gateway (yes, that’s right! A payment gateway)</li>
                        <li>And many more</li>
                    </p>
                    
                    <p className="bg-txt-black  mb-4">
                    If you’re a <strong>real estate agent</strong>, <strong>landlord</strong>, <strong>property developer</strong> (big or small) or someone looking for your new home, sign up to sociallocket.com today and get started. It’s <strong>FREE</strong> and <strong>EASY</strong>.
                    </p>

                <div className="mt-5">
                  <div className="theme-button">
                    <a href="https://app.sociallocket.com/register"><strong>Get Started</strong></a>
                  </div>
                </div>

                
              </div>
            </div>
            
          </div>
        </div>
      </section>

      <section className="our-team">
          <div className="container">
            <div className="row align-items-center flex-row-reverse">
            <div className="col-lg-6 col-md-6">
                <div className="text-center">
                  <a href="https://app.sociallocket.com/register">
                    <img src={Keys} className="img-fluid" alt="real-estate-property" />
                  </a>
                </div>
              </div>
              
              <div className="col-lg-6 col-md-6">
                <div className="connect-content mb-5">
                  <h2 className="white mb-4"><strong className="bg-txt-black mb-4">Earn</strong><span className="bg-blu"> additional income with your </span><strong className="bg-txt-black mb-4">Property</strong></h2>
                  <div className="mt-5">
                    <div className="theme-button">
                      <a href="https://app.sociallocket.com/register"><strong>Find out more</strong></a>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </section>  

      <Footer />
      {/* bootstrap jquery */}
      {/* bootstrap js */}
      {/* owl js */}
      {/* custom js */}
    </>
  );
};

export default BlogSellingYourPropertyInLondon;