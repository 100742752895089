import CountdownPage from "./pages/CountdownPage";
import Landing from "./pages/Landing";
import "./css/style.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import PageNotFound from "./pages/pageNotFound/PageNotFound";
import TermsConditions from "./pages/Term&Conditions/Terms-Conditions";
import PrivacyNotice from "./pages/Term&Conditions/Privacy-Notice";
import AcceptablePolicy from "./pages/Term&Conditions/Acceptable-Policy";
import CookiePolicy from "./pages/Term&Conditions/Cookie-Policy";
import AboutUs from "./pages/AboutUs/about-us";
import AdvertiseWithUs from "./pages/AdvertiseWithUs/advertise-with-us";
import ManageProperty from "./pages/ManageProperty/manage-property";
import ContactUs from "./pages/ContactUs/contact-us";
import DemoVideo from "./pages/demo-video";
import Pricing from "./pages/pricing";
// import Services from "./pages/Services";
import Blog from "./pages/Blog/Blog";
import FAQ from "./pages/FAQ";
import BlogClients from "./pages/Blog/blog-pages/still-having-trouble-finding-clients"; //not posted yet
import BlogTrackingPortfolio from "./pages/Blog/blog-pages/benifits-of-tracking-your-portfolio";  //not posted yet
import BlogCryptoPayments from "./pages/Blog/blog-pages/why-crypto-payments-are-the-future";  //not posted yet
import BlogTokenisation from "./pages/Blog/blog-pages/how-tokenisation-is-changing-the-future-of-the-real-estate-industry";
import BlogInflaction from "./pages/Blog/blog-pages/the-impact-high-inflation-is-having-on-the-real-estate-industry";
import BlogFractionalisation from "./pages/Blog/blog-pages/the-power-of-fractionalised-assets";

import BlogAlternativeToZoopla from "./pages/Blog/blog-pages/alternative-to-zoopla"; //not posted yet
import BlogCheaperAlternativeToZoopla from "./pages/Blog/blog-pages/cheaper-alternative-to-zoopla"; //not posted yet
import BlogAlternativeToRightMove from "./pages/Blog/blog-pages/alternative-to-rightmove"; //not posted yet
import BlogCheaperAlternativeToRightMove from "./pages/Blog/blog-pages/cheaper-alternative-to-rightmove"; //not posted yet
import BlogAlternativeToOnTheMarket from "./pages/Blog/blog-pages/alternative-to-on-the-market"; //not posted yet
import BlogCheaperAlternativeToOnTheMarket from "./pages/Blog/blog-pages/cheaper-alternative-to-on-the-market"; //not posted yet
import BlogAlternativeToBayut from "./pages/Blog/blog-pages/alternative-to-bayut"; //not posted yet
import BlogCheaperAlternativeBayut from "./pages/Blog/blog-pages/cheaper-alternative-to-bayut"; //not posted yet
import BlogAlternativeToPropertyFinder from "./pages/Blog/blog-pages/alternative-to-property-finder"; //not posted yet
import BlogCheaperAlternativePropertyFinder from "./pages/Blog/blog-pages/cheaper-alternative-to-property-finder"; //not posted yet
import BlogBestPropertyManagementSoftwareInTheUAE from "./pages/Blog/blog-pages/best-property-management-software-in-the-uae"; //not posted yet
import BlogBestPropertyManagementSoftwareInDubai from "./pages/Blog/blog-pages/best-property-management-software-in-dubai"; //not posted yet
import BlogBestPropertyManagementSoftwareInTheUK from "./pages/Blog/blog-pages/best-property-management-software-in-the-uk"; //not posted yet
import BlogBestPropertyManagementSoftwareInLondon from "./pages/Blog/blog-pages/best-property-management-software-in-london"; //not posted yet
import BlogBestPropertyManagementSoftwareInBirmingham from "./pages/Blog/blog-pages/best-property-management-software-in-birmingham"; //not posted yet
import BlogAlternativePropertyManagementSoftwareToBlusky from "./pages/Blog/blog-pages/alternative-property-management-software-to-blusky"; //not posted yet
import BlogAlternativePropertyManagementSoftwareToElate from "./pages/Blog/blog-pages/alternative-property-management-software-to-elate"; //not posted yet
import BlogAlternativePropertyManagementSoftwareToAlto from "./pages/Blog/blog-pages/alternative-property-management-software-to-alto"; //not posted yet
import BlogAlternativePropertyManagementSoftwareToDoorLoop from "./pages/Blog/blog-pages/alternative-property-management-software-to-doorloop"; //not posted yet
import BlogAlternativePropertyManagementSoftwareToRentManager from "./pages/Blog/blog-pages/alternative-property-management-software-to-rent-manager"; //not posted yet
import BlogHowToGenerateMoreLeads from "./pages/Blog/blog-pages/how-to-generate-more-leads"; //not posted yet
import BlogSellingYourPropertyInTheUK from "./pages/Blog/blog-pages/selling-your-property-in-the-uk"; //not posted yet
import BlogSellingYourPropertyInLondon from "./pages/Blog/blog-pages/selling-your-property-in-london"; //not posted yet
import BlogSellingYourPropertyInBirmingham from "./pages/Blog/blog-pages/selling-your-property-in-birmingham"; //not posted yet
import BlogSellingYourPropertyInTheUAE from "./pages/Blog/blog-pages/selling-your-property-in-the-uae"; //not posted yet
import BlogSellingYourPropertyInDubai from "./pages/Blog/blog-pages/selling-your-property-in-dubai"; //not posted yet
import BlogPropertyManagementInTheUAE from "./pages/Blog/blog-pages/property-management-in-the-uae"; //not posted yet
import BlogPropertyManagementInDubai from "./pages/Blog/blog-pages/property-management-in-dubai"; //not posted yet
import BlogPropertyManagementInTheUK from "./pages/Blog/blog-pages/property-management-in-the-uk"; //not posted yet
import BlogPropertyManagementInLondon from "./pages/Blog/blog-pages/property-management-in-london"; //not posted yet
import BlogPropertyManagementInBirmingham from "./pages/Blog/blog-pages/property-management-in-birmingham"; //not posted yet
import BlogCheapPropertiesInTheUK from "./pages/Blog/blog-pages/cheap-properties-in-the-uk"; //not posted yet
import BlogCheapPropertiesInLondon from "./pages/Blog/blog-pages/cheap-properties-in-london"; //not posted yet
import BlogCheapPropertiesInBirmingham from "./pages/Blog/blog-pages/cheap-properties-in-birmingham"; //not posted yet
import BlogCheapPropertiesInTheUAE from "./pages/Blog/blog-pages/cheap-properties-in-the-uae"; //not posted yet
import BlogCheapPropertiesInDubai from "./pages/Blog/blog-pages/cheap-properties-in-dubai"; //not posted yet

import HowItWorks from "./pages/HowItWorks/how-it-works"; //not posted yet
import HowItWorksAgents from "./pages/HowItWorks/how-it-works-agent"; //not posted yet
import HowItWorksTenants from "./pages/HowItWorks/how-it-works-tenants"; //not posted yet
import HowItWorksLandlords from "./pages/HowItWorks/how-it-works-landlords"; //not posted yet
import HowItWorksPropertyDevelopers from "./pages/HowItWorks/how-it-works-property-developers"; //not posted yet

import ServicesAndFeatures from "./pages/Services/services-and-features";
import ServicesAdvertise from "./pages/Services/advertise";
import ServicesProjectManagement from "./pages/Services/project-management"; 
import ServicesCustomerRelationManagement from "./pages/Services/customer-relationship-management";
import ServicesPorfolioTracking from "./pages/Services/porfolio-tracking"; 
import ServicesPaymentGateway from "./pages/Services/payment-gateway";
import ServicesTools from "./pages/Services/tools-and-apps";
import Integrations from "./pages/Integrations/integrations";

const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Landing />,
    },
    {
      path: "/presale",
      element: <CountdownPage />,
    },
    {
      path: "*",
      element: <PageNotFound />,
    },
    {
      path: "/terms-conditions",
      element: <TermsConditions />,
    },
    {
      path: "/privacy-notice",
      element: <PrivacyNotice />,
    },
    {
      path: "/acceptable-policy",
      element: <AcceptablePolicy />,
    },
    {
      path: "/cookie-policy",
      element: <CookiePolicy />,
    },
    {
      path: "/faq",
      element: <FAQ />,
    },
    {
      path: "/about-us",
      element: <AboutUs />,
    },
    {
      path: "/contact-us",
      element: <ContactUs />,
    },
    {
      path: "/advertise-with-us",
      element: <AdvertiseWithUs />,
    },
    {
      path: "/manage-property",
      element: <ManageProperty />,
    },
    {
      path: "/demo",
      element: <DemoVideo />,
    },
    {
      path: "/pricing",
      element: <Pricing />,
    },
    // {
    //   path: "/services",
    //   element: <Services />,
    // },
    {
      path: "/blog",
      element: <Blog />,
    },
    {
      path: "/blog/still-having-trouble-finding-clients",
      element: <BlogClients />,
    },
    {
      path: "/blog/benifits-of-tracking-your-portfolio",
      element: <BlogTrackingPortfolio />,
    },
    {
      path: "/blog/why-crypto-payments-are-the-future",
      element: <BlogCryptoPayments />,
    },
    {
      path: "/blog/the-power-of-fractionalised-assets",
      element: <BlogFractionalisation />,
    },
    {
      path: "/blog/how-tokenisation-is-changing-the-future-of-the-real-estate-industry",
      element: <BlogTokenisation/>,
    },
    {
      path: "/blog/the-impact-high-inflation-is-having-on-the-real-estate-industry",
      element: <BlogInflaction/>,
    },
    {
      path: "/blog/alternative-to-zoopla",
      element: <BlogAlternativeToZoopla />,
    },
    {
      path: "/blog/cheaper-alternative-to-zoopla",
      element: <BlogCheaperAlternativeToZoopla />,
    },
    {
      path: "/blog/alternative-to-rightmove",
      element: <BlogAlternativeToRightMove />,
    },
    {
      path: "/blog/cheaper-alternative-to-rightmove",
      element: <BlogCheaperAlternativeToRightMove />,
    },
    {
      path: "/blog/alternative-to-on-the-market",
      element: <BlogAlternativeToOnTheMarket/>,
    },
    {
      path: "/blog/cheaper-alternative-to-on-the-market",
      element: <BlogCheaperAlternativeToOnTheMarket/>,
    },
    {
      path: "/blog/alternative-to-bayut",
      element: <BlogAlternativeToBayut />,
    },
    {
      path: "/blog/cheaper-alternative-to-bayut",
      element: <BlogCheaperAlternativeBayut />,
    },
    {
      path: "/blog/alternative-to-property-finder",
      element: <BlogAlternativeToPropertyFinder />,
    },
    {
      path: "/blog/cheaper-alternative-to-property-finder",
      element: <BlogCheaperAlternativePropertyFinder />,
    },
    {
      path: "/blog/best-property-management-software-in-the-uae",
      element: <BlogBestPropertyManagementSoftwareInTheUAE/>,
    },
    {
      path: "/blog/best-property-management-software-in-dubai",
      element: <BlogBestPropertyManagementSoftwareInDubai/>,
    },
    {
      path: "/blog/best-property-management-software-in-the-uk",
      element: <BlogBestPropertyManagementSoftwareInTheUK/>,
    },
    {
      path: "/blog/best-property-management-software-in-london",
      element: <BlogBestPropertyManagementSoftwareInLondon/>,
    },
    {
      path: "/blog/best-property-management-software-in-birmingham",
      element: <BlogBestPropertyManagementSoftwareInBirmingham/>,
    },
    {
      path: "/blog/alternative-property-management-software-to-blusky",
      element: <BlogAlternativePropertyManagementSoftwareToBlusky />,
    },
    {
      path: "/blog/alternative-property-management-software-to-elate",
      element: <BlogAlternativePropertyManagementSoftwareToElate />,
    },
    {
      path: "/blog/alternative-property-management-software-to-alto",
      element: <BlogAlternativePropertyManagementSoftwareToAlto />,
    },
    {
      path: "/blog/alternative-property-management-software-to-doorloop",
      element: <BlogAlternativePropertyManagementSoftwareToDoorLoop />,
    },
    {
      path: "/blog/alternative-property-management-software-to-rent-manager",
      element: <BlogAlternativePropertyManagementSoftwareToRentManager/>,
    },
    {
      path: "/blog/how-to-generate-more-leads",
      element: <BlogHowToGenerateMoreLeads/>,
    },
    {
      path: "/blog/selling-your-property-in-the-uk",
      element: <BlogSellingYourPropertyInTheUK/>,
    },
    {
      path: "/blog/selling-your-property-in-london",
      element: <BlogSellingYourPropertyInLondon/>,
    },
    {
      path: "/blog/selling-your-property-in-birmingham",
      element: <BlogSellingYourPropertyInBirmingham/>,
    },
    {
      path: "/blog/selling-your-property-in-the-uae",
      element: <BlogSellingYourPropertyInTheUAE/>,
    },
    {
      path: "/blog/selling-your-property-in-dubai",
      element: <BlogSellingYourPropertyInDubai/>,
    },
    {
      path: "/blog/property-management-in-the-uae",
      element: <BlogPropertyManagementInTheUAE/>,
    },
    {
      path: "/blog/property-management-in-dubai",
      element: <BlogPropertyManagementInDubai/>,
    },
    {
      path: "/blog/property-management-in-the-uk",
      element: <BlogPropertyManagementInTheUK/>,
    },
    {
      path: "/blog/property-management-in-london",
      element: <BlogPropertyManagementInLondon/>,
    },
    {
      path: "/blog/property-management-in-birmingham",
      element: <BlogPropertyManagementInBirmingham/>,
    },
    {
      path: "/blog/cheap-properties-in-the-uk",
      element: <BlogCheapPropertiesInTheUK/>,
    },
    {
      path: "/blog/cheap-properties-in-london",
      element: <BlogCheapPropertiesInLondon/>,
    },
    {
      path: "/blog/cheap-properties-in-birmingham",
      element: <BlogCheapPropertiesInBirmingham/>,
    },
    {
      path: "/blog/cheap-properties-in-the-uae",
      element: <BlogCheapPropertiesInTheUAE/>,
    },
    {
      path: "/blog/cheap-properties-in-dubai",
      element: <BlogCheapPropertiesInDubai/>,
    },
    {
      path: "/how-it-works",
      element: <HowItWorks/>,
    },
    {
      path: "/how-it-works-agent",
      element: <HowItWorksAgents/>,
    },

    {
      path: "/how-it-works-tenants",
      element: <HowItWorksTenants/>,
    },
    {
      path: "/how-it-works-landlords",
      element: <HowItWorksLandlords/>,
    },
    {
      path: "/how-it-works-property-developers",
      element: <HowItWorksPropertyDevelopers/>,
    },
    {
      path: "/services/advertise",
      element: <ServicesAdvertise/>,
    },
    {
      path: "/services/tools-and-apps",
      element: <ServicesTools/>,
    },
    {
      path: "/services/project-management",
      element: <ServicesProjectManagement/>,
    },
    {
      path: "/services/customer-relationship-management",
      element: <ServicesCustomerRelationManagement/>,
    },
    {
      path: "/services/portfolio-tracking",
      element: <ServicesPorfolioTracking/>,
    },
    {
      path: "/services/payment-gateway",
      element: <ServicesPaymentGateway/>,
    },
    {
      path: "/services",
      element: <ServicesAndFeatures/>,
    },
    {
      path: "/integrations",
      element: <Integrations/>,
    }
  ]);
  return <RouterProvider router={router} />;
};
export default App;
