import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Header from "../../header-footer/header";
import Footer from "../../header-footer/footer";
import { useForm } from "react-hook-form";
import "../../../css/style.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { API } from "../../../services/ApiClient";
import Swal from "sweetalert2";
import OwlCarousel from 'react-owl-carousel2';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import InflationImage from "../../../assets/images/inflation.png";
// import AnimatedLogo from "../../../assets/images/blue-logo-white-bg.gif";
//import Faq from "../../../assets/images/faq.png";

const schema = yup.object({
  email: yup.string().email().required("Email is a required field"),
  name: yup.string().required("Name is a required field"),
  public_key: yup.string().required("Public key is a required field"),
  amount: yup
    .number()
    .typeError("Amount is a required field")
    .positive("Amount must be a positive number"),
});
const Landing = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [success, setSuccess] = useState(false);

  const onSubmit = async (data, event) => {
    event.preventDefault();
    API.post(process.env.REACT_APP_URL + "api/v1/contracts", data)
      .then((response) => {
        setSuccess(true);
        if (response) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Your form has been submitted.",
          });
          reset();
        }
      })
      .catch((error) => {
        if (error && error.response)
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error?.response?.data?.message,
          });
      });
  };
  const options = {
    items: 3,
    nav: false,
    dots:true,
    rewind: true,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    },
  };
  return (
    <>
      <Header />
      <section className="banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 banner-content">
              <h1 className="white mb-4">The Impact High Inflation Is Having on The Real Estate Industry</h1>
              <h4 className="bg-blu mb-2">Is Inflation Crushing The Economy? What's Going to Happen to the Average Household?</h4>
              <p className="white mb-4 col-lg-8">
               With <strong>Sociallocket</strong>, you can invest in real estate assets from as little as <strong>1%</strong> to <strong>100%</strong>. You now have full control of how, where and when you <strong>invest</strong>.
              </p>
              <div className="mt-lg-5">
                <div className="theme-button">
                  <a href="https://app.sociallocket.com/register"><strong>We Will Help You Fight Against Inflation</strong></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-us">
        <div className="container">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-lg-6 col-md-6">
              <div className="text-center mt-3">
                <img src={InflationImage} className="img-fluid" alt="InflationImage" />
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="connect-content mb-5">
                <h2 className="bg-txt-black  mb-4">Inflation Is on The Rise</h2>
                <h4 className="bg-txt-black  mb-4">
                Since Covid 19, we have seen an high increase in inflation affecting different industries with the Real Estate industry being one of many. 
                </h4>
                <h4 className="bg-txt-black  mb-4">
                With interest rates surging through the roof, causing many to be forced to either default on the mortgage, sell up or find alternative means to save themselves from drowning as part of keeping their head above water, something has to be done. 
                </h4>
                <h4 className="bg-txt-black  mb-4">
                For the last few years, we’ve planned around this by utilising complex algorithms, smart computing and the power of the blockchain. A technology that was made for the people, we decided to use it for the people. 
Instead of being restricted to live in environments and conditions due to budgets and financial constraints, we want to give you back the power by removing high interest rates and instead granting you the opportunity to capitalise from being rewarded for investing smart, wisely and securely.
                </h4>
                <div className="mt-5">
                  <div className="theme-button">
                    <a href="https://app.sociallocket.com/register"><strong>We Can Help</strong></a>
                  </div>
                </div>

                
              </div>
            </div>
            
          </div>
        </div>
      </section>



      <Footer />
      {/* bootstrap jquery */}
      {/* bootstrap js */}
      {/* owl js */}
      {/* custom js */}
    </>
  );
};

export default Landing;