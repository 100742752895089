import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Header from "../header-footer/header";
import Footer from "../header-footer/footer";
import { useForm } from "react-hook-form";
// import Aboutus from "../assets/images/about-us.png";
import crypto from "../../assets/images/crypto-main.png";
// import Laptop from "../assets/images/laptop.png";
// import pancakeswap from "../assets/images/uniswap.png";
// import uniswap from "../assets/images/pancakeswap.png";
// import Faq from "../assets/images/faq.png";
// import team from "../assets/images/team.jpg";
//import Hamid from "../assets/images/Hamid_Rahman.jpg";
// import Jermaine from "../assets/images/Jermaine_Anderson.jpg";
// import Rishabh from "../assets/images/Rishabh_Rawat.jpg";
import "../../css/style.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { API } from "../../services/ApiClient";
import Swal from "sweetalert2";
import OwlCarousel from 'react-owl-carousel2';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import DoesQa from "../assets/images/does-qa.png";
// import CyberScope from "../assets/images/partners/cyberscope.jpeg";
// import Blockchain from "../assets/images/future-blockchain-summit.jpeg";
// import CryptoExpo from "../assets/images/crypto-expo.png";
// import YahooFinance from "../assets/images/yahoo-finance.jpeg";

// import FractionalisationImage from "../../assets/images/fractionalisation.png";
// import InflationImage from "../../assets/images/inflation.png";
// import TokenisationImage from "../../assets/images/tokenisation.png";


import FractionalisationImage from "../../assets/images/blogs/fractionalisation.png";
import TokenisationImage from "../../assets/images/blogs/tokenisation.png";
import InflationImage from "../../assets/images/blogs/inflation.png";

import Keys from "../../assets/images/additional/keys-gold.png";
import ZooplaAltImage from "../../assets/images/blogs/zoopla-alt.png";
import CheaperZooplaImage from "../../assets/images/blogs/cheaper-zoopla.png";
import RightmoveAltImage from "../../assets/images/blogs/rightmove-alt.png";
import CheaperRightmoveImage from "../../assets/images/blogs/cheaper-rightmove.png";
import OnTheMarketAltImage from "../../assets/images/blogs/on-the-market-alt.png";
import CheaperOnTheMarketImage from "../../assets/images/blogs/cheaper-on-the-market.png";
import PropertyFinderAltImage from "../../assets/images/blogs/property-finder-alt.png";
import CheaperPropertyFinderImage from "../../assets/images/blogs/cheaper-property-finder.png";
import BayutAltImage from "../../assets/images/blogs/bayut-alt.png";
import CheaperBayutImage from "../../assets/images/blogs/cheaper-bayut.png";

import PropertyManagement1Image from "../../assets/images/blogs/property-management-1.png";
import PropertyManagement2Image from "../../assets/images/blogs/property-management-2.png";
import PropertyManagement3Image from "../../assets/images/blogs/property-management-3.png";
import PropertyManagement4Image from "../../assets/images/blogs/property-management-4.png";
import PropertyManagement5Image from "../../assets/images/blogs/property-management-5.png";
import PropertyManagement6Image from "../../assets/images/blogs/property-management-6.png";
import PropertyManagement7Image from "../../assets/images/blogs/property-management-7.png";
import PropertyManagement8Image from "../../assets/images/blogs/property-management-8.png";
import PropertyManagement9Image from "../../assets/images/blogs/property-management-9.png";
import PropertyManagement10Image from "../../assets/images/blogs/property-management-10.png";

import GenerateMoreLeadsImage from "../../assets/images/blogs/generate-more-leads.png";
import AdvertisePropertyUKImage from "../../assets/images/blogs/advertise-your-property-in-the-uk.png";
import AdvertisePropertyBirminghamImage from "../../assets/images/blogs/advertise-your-property-in-birmingham.png";
import AdvertisePropertyLondonImage from "../../assets/images/blogs/advertise-your-property-in-london.png";
import AdvertisePropertyUAEImage from "../../assets/images/blogs/advertise-your-property-in-the-uae.png";
import AdvertisePropertyDubaiImage from "../../assets/images/blogs/advertise-your-property-in-dubai.png";

import PropertyManagementDubaiImage from "../../assets/images/blogs/property-management-in-dubai.png";
import PropertyManagementUAEImage from "../../assets/images/blogs/property-management-in-uae.png";
import PropertyManagementUKImage from "../../assets/images/blogs/property-management-in-uk.png";
import PropertyManagementBirminghamImage from "../../assets/images/blogs/property-management-in-birmingham.png";
import PropertyManagementLondonImage from "../../assets/images/blogs/property-management-in-london.png";

import CheapDubaiImage from "../../assets/images/blogs/cheap1.png";
import CheapUAEImage from "../../assets/images/blogs/cheap2.png";
import CheapUKImage from "../../assets/images/blogs/cheap3.png";
import CheapLondonImage from "../../assets/images/blogs/cheap4.png";
import CheapBirminghamImage from "../../assets/images/blogs/cheap5.png";

import AssetManagement from "../../assets/images/asset-management/asset-management-main.jpg";

import PortfolioManagement from "../../assets/images/portfolio-management/portfolio-management-1-main.jpg";
import Developer from "../../assets/images/developer/developer-3-main.jpg";
import Advertise from "../../assets/images/advertise/advertise-1-main.jpg";
import CRM from "../../assets/images/crm/crm-1-main.jpg";


const schema = yup.object({
  email: yup.string().email().required("Email is a required field"),
  name: yup.string().required("Name is a required field"),
  public_key: yup.string().required("Public key is a required field"),
  amount: yup
    .number()
    .typeError("Amount is a required field")
    .positive("Amount must be a positive number"),
});
const Landing = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [success, setSuccess] = useState(false);

  const onSubmit = async (data, event) => {
    event.preventDefault();
    API.post(process.env.REACT_APP_URL + "api/v1/contracts", data)
      .then((response) => {
        setSuccess(true);
        if (response) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Your form has been submitted.",
          });
          reset();
        }
      })
      .catch((error) => {
        if (error && error.response)
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error?.response?.data?.message,
          });
      });
  };
  const options = {
    items: 3,
    nav: false,
    dots:true,
    rewind: true,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    },
  };
  return (
    <>
      <Header />
      <section className="blog-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 banner-content">
              <h1 className="white mb-4">Sociallocket Blog</h1>
              <h4 className="bg-blu mb-2">Better Informed, Better Investing, Better FUTURE!</h4>
              <p className="white mb-4">
              Stay upto date with <strong>Sociallockets</strong> latest news and reports on changes in the <strong>Real Estate</strong> market.
              </p>
              <div className="mt-lg-5">
                <div className="theme-button">
                  <a href="https://app.sociallocket.com/register"><strong>Invest In Your Future Today</strong></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our-team">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="team-head text-center">
              <h3 className="bg-blu mb-2"><strong>Real Estate Investing</strong>, Property Management, <strong>Tips</strong> and News</h3>

              {/* <h1 className="bg-txt-black text-center mb-4">Our Services</h1> */}

              </div>
            </div>
          </div>
          <div className="row">
          <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/the-power-of-fractionalised-assets">
                  <img
                    src={FractionalisationImage}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                
                <h4 className="mt-3">The Power of Fractionalised Assets</h4>
                <p>Read more...</p>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/how-tokenisation-is-changing-the-future-of-the-real-estate-industry">
                  <img
                    src={TokenisationImage}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">How Tokenisation is Changing the Real Estate Industry</h4>

                {/* <h4 className="bg-blu mt-3">Property Developer Portal</h4> */}
                <p>Read more...</p>
              
              </div>
            </div>

            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/the-impact-high-inflation-is-having-on-the-real-estate-industry">
                  <img
                    src={InflationImage}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">The Impact High Inflation Is Having on The Real Estate Industry</h4>

                {/* <h4 className="bg-blu mt-3">Property Developer Portal</h4> */}
                <p>Read more...</p>
              
              </div>
            </div>



            {/* <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/alternative-to-zoopla">
                  <img
                    src={ZooplaAltImage}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">Is Sociallocket an Alternative to Zoopla?</h4>

                <p>Read more...</p>
              
              </div>
            </div>

            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/cheaper-alternative-to-zoopla">
                  <img
                    src={CheaperZooplaImage}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">Can Sociallocket Really be a Cheaper Alternative to Zoopla?</h4>

                <p>Read more...</p>
              
              </div>
            </div>
            
            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/alternative-to-rightmove">
                  <img
                    src={RightmoveAltImage}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">Is Sociallocket an Alternative to Rightmove?</h4>

                <p>Read more...</p>
              
              </div>
            </div>

            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/cheaper-alternative-to-rightmove">
                  <img
                    src={CheaperRightmoveImage}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">Is Sociallocket an Cheaper Alternative to Rightmove?</h4>

                <p>Read more...</p>
              
              </div>
            </div>

            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/alternative-to-on-the-market">
                  <img
                    src={OnTheMarketAltImage}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">Sociallocket Could be an Alternative to On The Market!</h4>

                <p>Read more...</p>
              
              </div>
            </div>

            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/cheaper-alternative-to-on-the-market">
                  <img
                    src={CheaperOnTheMarketImage}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">Is Sociallocket Really a Cheaper Alternative to On The Market?</h4>

                <p>Read more...</p>
              
              </div>
            </div>


            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/alternative-to-bayut">
                  <img
                    src={BayutAltImage}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">An Alternative to Bayut?</h4>

                <p>Read more...</p>
              
              </div>
            </div>

            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/cheaper-alternative-to-bayut">
                  <img
                    src={CheaperBayutImage}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">Could Sociallocket be a Cheaper Alternative to Bayut?</h4>

                <p>Read more...</p>
              
              </div>
            </div>

            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/alternative-to-property-finder">
                  <img
                    src={PropertyFinderAltImage}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">An Alternative to Property Finder?</h4>

                <p>Read more...</p>
              
              </div>
            </div>

            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/cheaper-alternative-to-property-finder">
                  <img
                    src={CheaperPropertyFinderImage}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">A Cheaper Alternative to Property Finder</h4>

                <p>Read more...</p>
              
              </div>
            </div>

            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/best-property-management-software-in-the-uae">
                  <img
                    src={PropertyManagement1Image}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">The Best Property Management Software in the U.A.E</h4>

                <p>Read more...</p>
              
              </div>
            </div>

            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/best-property-management-software-in-dubai">
                  <img
                    src={PropertyManagement2Image}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">The Best Property Management Software in Dubai</h4>

                <p>Read more...</p>
              
              </div>

            </div>

            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/best-property-management-software-in-the-uk">
                  <img
                    src={PropertyManagement3Image}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">The Best Property Management Software in the U.K</h4>

                <p>Read more...</p>
              
              </div>
            </div>

            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/alternative-property-management-software-to-blusky">
                  <img
                    src={PropertyManagement4Image}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">An Alternative Property Management Software To Blusky</h4>

                <p>Read more...</p>
              
              </div>
            </div>

            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/alternative-property-management-software-to-elate">
                  <img
                    src={PropertyManagement5Image}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">An Alternative Property Management Software To Elate</h4>

                <p>Read more...</p>
              
              </div>
            </div>

            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/alternative-property-management-software-to-alto">
                  <img
                    src={PropertyManagement9Image}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">An Alternative Property Management Software To Alto</h4>

                <p>Read more...</p>
              
              </div>
            </div>

            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/alternative-property-management-software-to-doorloop">
                  <img
                    src={PropertyManagement10Image}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">An Alternative Property Management Software To DoorLoop</h4>

                <p>Read more...</p>
              
              </div>
            </div>

            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/alternative-property-management-software-to-rent-manager">
                  <img
                    src={PropertyManagement6Image}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">An Alternative Property Management Software To Rent Manager</h4>

                <p>Read more...</p>
              
              </div>
            </div>

            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/best-property-management-software-in-birmingham">
                  <img
                    src={PropertyManagement7Image}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">The Best Property Management Software in Birmingham</h4>

                <p>Read more...</p>
              
              </div>
            </div>

            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/best-property-management-software-in-london">
                  <img
                    src={PropertyManagement8Image}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">The Best Property Management Software in London</h4>

                <p>Read more...</p>
              
              </div>
            </div>

            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/how-to-generate-more-leads">
                  <img
                    src={GenerateMoreLeadsImage}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">How to Generate More Leads</h4>

                <p>Read more...</p>
              
              </div>
            </div>

            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/selling-your-property-in-birmingham">
                  <img
                    src={AdvertisePropertyBirminghamImage}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">Selling Your Property in Birmingham?</h4>

                <p>Read more...</p>
              
              </div>
            </div>

            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/selling-your-property-in-london">
                  <img
                    src={AdvertisePropertyLondonImage}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">Selling Your Property in London?</h4>

                <p>Read more...</p>
              
              </div>
            </div>

            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/selling-your-property-in-the-uk">
                  <img
                    src={AdvertisePropertyUKImage}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">Selling Your Property in the UK?</h4>

                <p>Read more...</p>
              
              </div>
            </div>

            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/selling-your-property-in-the-uae">
                  <img
                    src={AdvertisePropertyUAEImage}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">Selling Your Property in the U.A.E?</h4>

                <p>Read more...</p>
              
              </div>
            </div>

            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/selling-your-property-in-dubai">
                  <img
                    src={AdvertisePropertyDubaiImage}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">Selling Your Property in the Dubai?</h4>

                <p>Read more...</p>
              
              </div>
            </div>



            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/property-management-in-dubai">
                  <img
                    src={PropertyManagementDubaiImage}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">Property Management in Dubai</h4>

                <p>Read more...</p>
              
              </div>
            </div>

            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/property-management-in-the-uae">
                  <img
                    src={PropertyManagementUAEImage}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">Property Management in the UAE</h4>

                <p>Read more...</p>
              
              </div>
            </div>

            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/property-management-in-the-uk">
                  <img
                    src={PropertyManagementUKImage}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">Property Management in the UK</h4>

                <p>Read more...</p>
              
              </div>
            </div>

            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/property-management-in-birmingham">
                  <img
                    src={PropertyManagementBirminghamImage}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">Property Management in Birmingham</h4>

                <p>Read more...</p>
              
              </div>
            </div>

            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/property-management-in-dubai">
                  <img
                    src={PropertyManagementLondonImage}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">Property Management in London</h4>

                <p>Read more...</p>
              
              </div>
            </div>

            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/cheap-properties-in-the-uae">
                  <img
                    src={CheapUAEImage}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">Are you Looking for Cheap Properties in the U.A.E?</h4>

                <p>Read more...</p>
              
              </div>
            </div>

            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/cheap-properties-in-dubai">
                  <img
                    src={CheapDubaiImage}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">Are you Finding it Hard to Find Cheap Properties in Dubai?</h4>

                <p>Read more...</p>
              
              </div>
            </div>
            
            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/cheap-properties-in-the-uk">
                  <img
                    src={CheapUKImage}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">Still Looking for Cheap Properties in the UK?</h4>

                <p>Read more...</p>
              
              </div>
            </div>

            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/cheap-properties-in-london">
                  <img
                    src={CheapLondonImage}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">The Cheap Hidden Properties Located in Heart of London</h4>

                <p>Read more...</p>
              
              </div>
            </div>

            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/cheap-properties-in-birmingham">
                  <img
                    src={CheapBirminghamImage}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">Are There Still Cheap Properties Located in Birmingham?</h4>

                <p>Read more...</p>
              
              </div>
            </div> */}


            {/* <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/the-power-of-fractionalised-assets">
                  <img
                    src={FractionalisationImage}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                
                <h4 className="mt-3">The Power of Fractionalised Assets</h4>
                <p>Read more...</p>
              </div>
            </div>
          
            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/how-tokenisation-is-changing-the-future-of-the-real-estate-industry">
                  <img
                    src={TokenisationImage}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">How Tokenisation is Changing the Real Estate Industry</h4>

                <p>Read more...</p>
              
              </div>
            </div>

            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/blog/the-impact-high-inflation-is-having-on-the-real-estate-industry">
                  <img
                    src={InflationImage}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">The Impact High Inflation Is Having on The Real Estate Industry</h4>

                <p>Read more...</p>
              
              </div>
            </div> */}

            

            {/* <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="https://app.sociallocket.com/register">
                  <img
                    src={CRM}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="mt-3">Are Your Clients Suffering?</h4>
                <p>Still struggling with managing your relations with your clients or tenant? That stops here. We have all the tools needed to solve all your problems!</p>

              </div>
            </div> */}

            {/* <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="https://app.sociallocket.com/register">
                  <img
                    src={PortfolioManagement}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="mt-3">Benifits of Portfolio Tracking</h4>
                <p>We believe data is king. Use real time metrics to visually represent property portfolios and best ways to accumulate wealth.</p>


              </div>
            </div> */}

            {/* <div className="col-lg-4">
              <div className="member">
              <div className="">
              <a href="presale">
                  <img
                    src={crypto}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                  
                </a>
                </div>

                <h4 className="mt-3">The Future of Crypto Payments</h4>
                <p>We accept crypto payments, which means you can pay for your property using both crypto currency and other traditional forms of payments.</p>

              </div>
            </div> */}
          </div>
        </div>
      </section>

      <section className="our-team">
          <div className="container">
            <div className="row align-items-center flex-row-reverse">
            <div className="col-lg-6 col-md-6">
                <div className="text-center">
                  <a href="https://app.sociallocket.com/register">
                    <img src={Keys} className="img-fluid" alt="real-estate-property" />
                  </a>
                </div>
              </div>
              
              <div className="col-lg-6 col-md-6">
                <div className="connect-content mb-5">
                  <h2 className="white mb-4"><strong className="bg-txt-black mb-4">Earn</strong><span className="bg-blu"> additional income with your </span><strong className="bg-txt-black mb-4">Property</strong></h2>
                  <div className="mt-5">
                    <div className="theme-button">
                      <a href="https://app.sociallocket.com/register"><strong>Get Started Today</strong></a>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </section>  

      <Footer />
      {/* bootstrap jquery */}
      {/* bootstrap js */}
      {/* owl js */}
      {/* custom js */}
    </>
  );
};

export default Landing;