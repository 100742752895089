import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Header from "../../header-footer/header";
import Footer from "../../header-footer/footer";
import { useForm } from "react-hook-form";
import "../../../css/style.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { API } from "../../../services/ApiClient";
import Swal from "sweetalert2";
import OwlCarousel from 'react-owl-carousel2';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import TokenisationImage from "../../../assets/images/tokenisation.png";
import AnimatedLogo from "../../../assets/images/blue-logo-white-bg.gif";
//import Faq from "../../../assets/images/faq.png";

const schema = yup.object({
  email: yup.string().email().required("Email is a required field"),
  name: yup.string().required("Name is a required field"),
  public_key: yup.string().required("Public key is a required field"),
  amount: yup
    .number()
    .typeError("Amount is a required field")
    .positive("Amount must be a positive number"),
});
const Landing = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [success, setSuccess] = useState(false);

  const onSubmit = async (data, event) => {
    event.preventDefault();
    API.post(process.env.REACT_APP_URL + "api/v1/contracts", data)
      .then((response) => {
        setSuccess(true);
        if (response) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Your form has been submitted.",
          });
          reset();
        }
      })
      .catch((error) => {
        if (error && error.response)
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error?.response?.data?.message,
          });
      });
  };
  const options = {
    items: 3,
    nav: false,
    dots:true,
    rewind: true,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    },
  };
  return (
    <>
      <Header />
      <section className="banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 banner-content">
              <h1 className="white mb-4">How Tokenisation is Changing the Future of the Real Estate Industry</h1>
              <h4 className="bg-blu mb-2">Never Worry About The Security of Your Investments Again!</h4>
              <p className="white mb-4 col-lg-8">
               With <strong>Sociallocket</strong>, you can invest in real estate assets from as little as <strong>1%</strong> to <strong>100%</strong>. You now have full control of how, where and when you <strong>invest</strong>.
              </p>
              <div className="mt-lg-5">
                <div className="theme-button">
                  <a href="https://app.sociallocket.com/register"><strong>1% to 100% Ownership. Start Investing Today</strong></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-us">
        <div className="container">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-lg-6 col-md-6">
              <div className="text-center mt-3">
                <img src={TokenisationImage} className="img-fluid" alt="TokenisationImage" />
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="connect-content mb-5">
                <h2 className="bg-txt-black  mb-4">Tokenisation</h2>
                <h4 className="bg-txt-black  mb-4">
                If you haven’t noticed, the real estate industry is fragmented! For some reason, many rather put up with the painful pot holes it poses rather than making things easier. With real estate being the asset class everyone strives to be a part of, we’ve thought about ways that can make this possible. 
                </h4>
                <h4 className="bg-txt-black  mb-4">
                We’ve also thought about the security of your investments by introducing secure and reliable methods to protect your investments like never before. With corruption occurring across the globe, it was more important now more than ever for us to not only understand why but how to solve this! <strong>Tokenisation</strong> was and is the answer! 

                By tying your physical assets to a digital representation, tokenisation allows you to now, track, manage, a facilitate all transactions and actions around your property and or real estate assets. Even “Wills”. Yes, that’s right, even “Wills”. 
                </h4>
                <h4 className="bg-txt-black  mb-4">
                So, this means no more arguing over ownership as well as watching loving families break down whilst at war over assets that have been left behind by loved ones.
                </h4>
                <div className="mt-5">
                  <div className="theme-button">
                    <a href="https://app.sociallocket.com/register"><strong>Are You Ready to Invest?</strong></a>
                  </div>
                </div>

                
              </div>
            </div>
            
          </div>
        </div>
      </section>



      <Footer />
      {/* bootstrap jquery */}
      {/* bootstrap js */}
      {/* owl js */}
      {/* custom js */}
    </>
  );
};

export default Landing;