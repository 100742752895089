import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Header from "../../header-footer/header";
import Footer from "../../header-footer/footer";
import { useForm } from "react-hook-form";
import "../../../css/style.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { API } from "../../../services/ApiClient";
import Swal from "sweetalert2";
import OwlCarousel from 'react-owl-carousel2';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const schema = yup.object({
  email: yup.string().email().required("Email is a required field"),
  name: yup.string().required("Name is a required field"),
  public_key: yup.string().required("Public key is a required field"),
  amount: yup
    .number()
    .typeError("Amount is a required field")
    .positive("Amount must be a positive number"),
});
const Landing = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [success, setSuccess] = useState(false);

  const onSubmit = async (data, event) => {
    event.preventDefault();
    API.post(process.env.REACT_APP_URL + "api/v1/contracts", data)
      .then((response) => {
        setSuccess(true);
        if (response) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Your form has been submitted.",
          });
          reset();
        }
      })
      .catch((error) => {
        if (error && error.response)
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error?.response?.data?.message,
          });
      });
  };
  const options = {
    items: 3,
    nav: false,
    dots:true,
    rewind: true,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    },
  };
  return (
    <>
      <Header />
      <section className="banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 banner-content">
              <h1 className="white mb-4">Real Estate Agency</h1>
              <h4 className="bg-blu mb-2">Where Here to Help You Buy, Sell and Manage Your Property Today!</h4>
              <p className="white mb-4">
              <strong>Sociallocket</strong>, the blockchain that connects <strong>buyers</strong> and <strong>sellers</strong> together. Designed to <strong>streamline your acquisitions</strong>, <strong>saving</strong> you <strong>time</strong> and <strong>money</strong>.
              </p>
              <div className="mt-lg-5">
                <div className="theme-button">
                  <a href="https://app.sociallocket.com/register"><strong>Create A Free Account</strong></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      {/* bootstrap jquery */}
      {/* bootstrap js */}
      {/* owl js */}
      {/* custom js */}
    </>
  );
};

export default Landing;