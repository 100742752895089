import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Logo from "../../assets/images/logo.png";

const Footer = () => {
   
  
    return (
      <div>  
        <footer>
            <div className="container">
            <div className="row">
                <div className="col-md-4">
                    <div className="footer-logo">
                        <a href="/"><img src={Logo} /></a>
                        <h3 className="mt-4 white">Get Involved. Join our Community </h3>
                        {/* <p className="mt-4 white">Meydan Hotel, 6th Floor, Meydan Rd, Nad Al Sheba 1, Dubai, UAE</p> */}
                        <div className="social-media col-lg-12">
                       
                        {/* <h4 className="white">Social Media</h4> */}
                        {/* <hr style={{ color: "#ccc" }} /> */}
                        <ul className="justify-content-center">
                        <li>
                            <a href="https://www.facebook.com/people/Sociallocket/100093749312588/">
                            <i className="fa-brands fa-facebook-f" />
                            <span>Facebook</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://twitter.com/social_locket">
                            <i className="fa-brands fa-twitter" />
                            <span>Twitter</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://instagram.com/social_locket">
                            <i className="fa-brands fa-instagram" />
                            <span>Instagram</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/company/sociallocket/">
                            <i className="fa-brands fa-linkedin" />
                            <span>LinkedIn</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com/@Sociallocket">
                            <i className="fa-brands fa-youtube" />
                            <span>YouTube</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.tiktok.com/@social_locket">
                            <i className="fa-brands fa-tiktok" />
                            <span>TikTok</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.reddit.com/user/sociallocket">
                            <i className="fa-brands fa-reddit" />
                            <span>Reddit</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://discord.com/channels/1109444072332087316/1109444072776663121">
                            <i className="fa-brands fa-discord" />
                            <span>Discord</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://t.me/+oIfE_V72MTFkNTZk">
                            <i className="fa-brands fa-telegram" />
                            <span>Telegram</span>
                            </a>
                        </li>
                        </ul>
                    </div>

                    </div>
                </div>


                <div className="col-md-4">
                    <div className="social-media col-lg-12">
                        <h4 className="white">Our Services</h4>
                        <hr style={{ color: "#ccc" }} />
                        <ul className="justify-content-center">
                        <li>
                            <a href="https://app.sociallocket.com/register">
                                <span>Create a <strong className="bg-blu">Property ad</strong></span>
                            </a>
                        </li>
                        <li>
                            <a href="https://app.sociallocket.com/register">
                                <span>Manage <strong className="bg-blu">Portfolio</strong></span>
                            </a>
                        </li>
                        <li>
                            <a href="https://app.sociallocket.com/register">
                                <span>Manage a <strong className="bg-blu">Property</strong></span>
                            </a>
                        </li>
                        <li>
                            <a href="https://app.sociallocket.com/register">
                                <span>Manage <strong className="bg-blu">Tenants</strong></span>
                            </a>
                        </li>
                        <li>
                            <a href="https://app.sociallocket.com/search">
                                <span>Buy a <strong className="bg-blu">Property</strong></span>
                            </a>
                        </li>
                        <li>
                            <a href="https://app.sociallocket.com/search">
                                <span>Rent a <strong className="bg-blu">Property</strong></span>
                            </a>
                        </li>
                        <li>
                            <a href="https://app.sociallocket.com/register">
                                <span>For <strong className="bg-blu">Estate Agents</strong></span>
                            </a>
                        </li>
                        <li>
                            <a href="https://app.sociallocket.com/register">
                                <span>For <strong className="bg-blu">Agents</strong></span>
                            </a>
                        </li>
                        <li>
                            <a href="https://app.sociallocket.com/register">
                                <span>For <strong className="bg-blu">Landlords</strong></span>
                            </a>
                        </li>
                        <li>
                            <a href="https://app.sociallocket.com/search">
                                <span>For <strong className="bg-blu">Tenants</strong></span>
                            </a>
                        </li>
                        <li>
                            <a href="/advertise-with-us">
                                <span>Advertise with Us <strong className="bg-blu"></strong></span>
                            </a>
                        </li>
                        <li>
                            <a href="/manage-property">
                                <span>Manage your Property<strong className="bg-blu"></strong></span>
                            </a>
                        </li>
                        </ul>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="policy">
                        <h4 className="white">Useful Links</h4>
                        <hr style={{ color: "#ccc" }} />
                        <ul>
                        <li>
                            <a className="" href="https://app.sociallocket.com/register">
                            <strong>Agents </strong><strong className="bg-blu">Sign Up</strong>
                            </a>
                        </li>
                        {/* <li>
                            <a className="" href="/pricing">
                            <strong>Pricing</strong>
                            </a>
                        </li> */}
                        <li class="nav-item">
                            <a className="nav-link" href="https://app.sociallocket.com/register">
                            <strong>Advertise </strong><strong className="bg-blu">Property</strong>
                            </a>
                        </li>
                        <li>
                            <a className="" href="/integrations">
                            <strong>Integrations</strong>
                            </a>
                        </li>
                        <li>
                            <a className="" href="/how-it-works">
                            <strong>How</strong> <strong className="bg-blu">It Works</strong>
                            </a>
                        </li>
                        <li>
                            <a href="/terms-conditions">Terms &amp; Conditions of Services </a>
                        </li>
                        {/* <li>
                            <a href="/presale">Presale</a>
                        </li>
                        <li>
                            <a className="" href="https://sociallocket.gitbook.io/sociallocket/">
                            White Paper
                            </a>
                        </li>
                        <li>
                            <a className="" href="https://sociallocket.gitbook.io/sociallocket/tokenomics/usdslt-token">
                            Tokenomics
                            </a>
                        </li> */}
                        <li>
                            <a className="" href="/blog">
                            Blog
                            </a>
                        </li>
                        <li>
                            <a className="" href="/faq">
                            FAQs
                            </a>
                        </li>
                        <li>
                            <a className="" href="/about-us">
                            About Us
                            </a>
                        </li>
                        <li>
                            <a className="" href="/contact-us">
                            Contact Us
                            </a>
                        </li>
                        <li class="nav-item">
                            <strong>
                                <a className="nav-link" href="tel:+4402034881143">
                                    Call <strong className="bg-blu">+44 (0) 203 488 1143</strong>
                                </a>
                            </strong>
                        </li>
                        {/* <li>
                            <a className="" href="mailto:jermaine.anderson@sociallocket.com?subject=Message to the CEO">
                            Contact Us
                            </a>
                        </li> */}
                        </ul>
                    </div>
                </div>
            </div>
            </div>
            <div>
            <hr style={{ color: "#ccc" }} />
            </div>
            <div className="container policy-copyright">
            <div className="row">                
                <div className="col-md-12 col-lg-12 col-sm-12">
                <div className="copyright text-center">
                    <p>Copyright © Social Locket. All Right Reserved.</p>
                </div>
                </div>
            </div>
            </div>
        </footer>

      </div>
    );
  };
  
  export default Footer;      