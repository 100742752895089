import React, { useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Header from "./header-footer/header";
import Footer from "./header-footer/footer";
import "../css/style.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { API } from "../services/ApiClient";
import { useForm } from "react-hook-form";
import CountdownTimer from "../components/CountdownTimer";
import Swal from "sweetalert2";
import OwlCarousel from 'react-owl-carousel2';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import DoesQa from "../assets/images/does-qa.png";
import pancakeswap from "../assets/images/uniswap.png";
import uniswap from "../assets/images/pancakeswap.png";
import crypto from "../assets/images/crypto.png";
import TokenMetrics from "../assets/images/token-metrics-main.png";

// import currency from "../../assets/currency1.jpg"
import currency from "../assets/sociallocket.png";

import Blockchain from "../assets/images/partners/future-blockchain-summit.jpeg";
import CryptoExpo from "../assets/images/partners/crypto-expo.png";
import YahooFinance from "../assets/images/partners/yahoo-finance.jpeg";
import CyberScope from "../assets/images/partners/cyberscope.jpeg";
import Vincitore from "../assets/images/partners/vincitore.png";
import Binghatti from "../assets/images/partners/binghatti.jpeg";
import Danube from "../assets/images/partners/danube.png";


const schema = yup.object({
  email: yup.string().email().required("Email is a required field"),
  name: yup.string().required("Name is a required field"),
  public_key: yup.string().required("Public key is a required field"),
  amount: yup
    .number()
    .typeError("Amount  is a required field")
    .positive("Amount must be a positive number"),
  // amount: yup
  //   .mixed()

  //   .test("positive", "Amount must be greater than zero", (value) => {
  //     return typeof value === "number" && value > 0;
  //   }),
});
const CountdownPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [success, setSuccess] = useState(false);
  const onSubmit = async (data, event) => {
    event.preventDefault();
    API.post(process.env.REACT_APP_URL + "api/v1/contracts", data)
      .then((response) => {
        setSuccess(true);
        console.log("response: ", response);
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Your form has been submitted.",
        });
        reset();
      })
      .catch((error) => {
        console.error("error----", error);
        if (error && error.response)
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error?.response?.data?.message,
          });
      });
  };
  const options = {
    items: 3,
    nav: false,
    dots:true,
    rewind: true,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    },
  };

  // const { tokenBalance } = useContext(PreSaleContext);

  return (
    <>
      <Header />


      <section
        className="go-live"
        style={{
          background: "url(assests/images/about-bg-pettern.png) #f1f1f1",
          backgroundPosition: "left center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6">
              <CountdownTimer duration={86400 * 5} />
            </div>
            <div className="col-lg-6">
              <div className="login-box-2">
                <div className="text-center">
                  <h2 className="mt-3">Limited Availability</h2>
                  <p className="mb-5 m-auto">
                    So lets get you signed up before you miss your spot
                  </p>
                </div>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="count-form"
                  style={{ display: "inherit" }}
                >
                  <div className="user-box">
                    <div class="position-relative">
                      <input
                        type="text"
                        name="name"
                        {...register("name")}
                        required=""
                      />
                      <label>Full Name*</label>
                    </div>
                    {errors.name?.message ? (
                      <div className="alert alert-danger" role="alert">
                        {errors?.name?.message}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="user-box">
                    <div class="position-relative">
                      <input
                        type="text"
                        name="email"
                        required=""
                        {...register("email")}
                      />
                      <label>Email Id*</label>
                    </div>
                    {errors.email?.message ? (
                      <div className="alert alert-danger" role="alert">
                        {errors?.email?.message}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="user-box">
                    <div class="position-relative">
                      <input
                        type="text"
                        name="public_key"
                        {...register("public_key")}
                        required=""
                      />
                      <label>Public key*</label>
                    </div>
                    {errors.public_key?.message ? (
                      <div className="alert alert-danger" role="alert">
                        {errors?.public_key?.message}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="user-box">
                    <div class="position-relative">
                      <input
                        type="number"
                        name="amount"
                        {...register("amount", { valueAsNumber: true })}
                        required=""
                      />
                      <label>Amount*</label>
                    </div>
                    {errors.amount?.message ? (
                      <div className="alert alert-danger" role="alert">
                        {errors?.amount?.message}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="text-center ">
                    <button type="submit">
                      <span />
                      <span />
                      <span />
                      <span />
                      Pre Sale
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our-partner pb-0">
        <div className="container">
          <div className="row">
              <div className="col-lg-12">
              <h2 className="bg-txt-black text-center mb-5">Our Partners & Features</h2>
                <OwlCarousel options={options}>
                  <div className="item">
                      <img src={YahooFinance} className="img-fluid" alt="client-logos" width={200}  />
                  </div> 
                  <div className="item">
                      <img src={Binghatti} className="img-fluid" alt="client-logos" width={200}  />
                  </div>
                  <div className="item">
                      <img src={Danube} className="img-fluid" alt="client-logos" width={200}  />
                  </div>
                  <div className="item">
                      <img src={Vincitore} className="img-fluid" alt="client-logos" width={200}  />
                  </div>
                  <div className="item">
                      <img src={Blockchain} className="img-fluid" alt="client-logos" width={200}  />
                  </div>
                  <div className="item">
                      <img src={CryptoExpo} className="img-fluid" alt="client-logos" width={200}  />
                  </div>
                  {/* <div className="item">
                      <img src={CyberScope} className="img-fluid" alt="client-logos" width={200}  />
                  </div> */}
                  {/* <div className="item">
                      <img src={DoesQa} className="img-fluid" alt="client-logos" width={200}  />
                  </div> */}

                  <div className="item">
                      <img src={YahooFinance} className="img-fluid" alt="client-logos" width={200}  />
                  </div> 
                  <div className="item">
                      <img src={Binghatti} className="img-fluid" alt="client-logos" width={200}  />
                  </div>
                  <div className="item">
                      <img src={Danube} className="img-fluid" alt="client-logos" width={200}  />
                  </div>
                  <div className="item">
                      <img src={Vincitore} className="img-fluid" alt="client-logos" width={200}  />
                  </div>
                  <div className="item">
                      <img src={Blockchain} className="img-fluid" alt="client-logos" width={200}  />
                  </div>
                  <div className="item">
                      <img src={CryptoExpo} className="img-fluid" alt="client-logos" width={200}  />
                  </div>
                  {/* <div className="item">
                      <img src={CyberScope} className="img-fluid" alt="client-logos" width={200}  />
                  </div> */}
                </OwlCarousel>
              </div>
          </div>
        </div>
      </section>



      {/* <section className="our-team">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="team-head text-center">
              <h1 className="bg-blu mb-2"><strong>Token</strong> Metrics</h1>

              
              <div className="col-lg-6 m-auto">
              <div className="text-center">
                <img
                  src={TokenMetrics}
                  className="img-fluid"
                  alt="crypto"
                  width="600"
                  height="600"
                />
              </div>
            </div>

              </div>
            </div>
          </div>
        </div>
      </section> */}


      {/* <section className="about-us">
        <div className="container">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-lg-6 col-md-6">
              <div className="text-center">
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/YYwGN2OautA"></iframe>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="connect-content mb-5">
                <h2 className="mb-4">Tokenomics</h2>
                <p className="mb-4">
                See how <strong>Sociallocket</strong> can save you <strong>time</strong> and <strong>money</strong> whilst instilling <strong>trust</strong> and <strong>confidence!</strong>
                </p>
                <div className="mt-5">
                  <div className="theme-button">
                    <a href="https://calendly.com/sociallocket"><strong>How we can Help!</strong></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="our-team">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="team-head text-center">
              <h1 className="bg-blu mb-2"><strong>Token</strong> Metrics</h1>

              
              <div className="col-lg-6 m-auto">
              <div className="text-center">
                <img
                  src={TokenMetrics}
                  className="img-fluid"
                  alt="crypto"
                  width="600"
                  height="600"
                />
              </div>
            </div>

              </div>
            </div>
          </div>
        </div>
      </section>

			{/* <section className="max-w-screen-lg w-full m-auto mb-[3.5rem] md:mb-0 ">
				<div className="my-4 grid md:grid-cols-2 grid-cols-1 gap-x-[12px] gap-y-[12px]">
					<div className="balance_card_container left">
						<div className="left">
							<h2>my balance</h2>
							<div className="left_bottom">
								<img src={currency} alt="currency" />
								<span>{tokenBalance}</span>
							</div>
						</div>
						<div className="right">
							<h2>SLT Presale Price</h2>
							<div className="right_bottom">
								<span>$0.20</span>
							</div>
						</div>
					</div>
					<div className="balance_card_container right">
						<span>
							You will earn 5% in USDT from all purchases of your referrals.
						</span>
					</div>
				</div>
				<div className="py-4 grid grid-cols-1 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2  gap-x-[12px] gap-y-[12px] ">
					<Card amount={500} />
					<Card amount={1000} />
					<Card amount={2000} />

					<Card amount={5000} />
					<Card amount={10000} />
					<Card amount={20000} />

					<Card amount={50000} newClass={`lg:col-span-2 col-span-1`} />
				</div>
			</section> */}


      <section className="about-us bg-blue">
        <div className="container">
          <div className="row flex-row-reverse">
            <div className="col-lg-6">
              <div className="about-us-content mb-5">
                <div className="line-tag">
                  <h5 className="bg-blu mb-3">Sociallocket Tokenomics</h5>
                  <span>&nbsp;</span>
                </div>
                <h2>Sociallocket will be available on</h2>
                <div className="writer-reader">
                  <ul
                    className="nav nav-pills mb-3"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item writer" role="presentation">
                      <button
                        className="nav-link active"
                        id="pills-pancakeswap-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-pancakeswap"
                        type="button"
                        role="tab"
                        aria-controls="pills-pancakeswap"
                        aria-selected="true"
                      >
                        <img
                          src={pancakeswap}
                          className="img-fluid"
                          alt="pancakeswap"
                        />
                      </button>
                    </li>
                    <li className="nav-item reader" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-uniswap-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-uniswap"
                        type="button"
                        role="tab"
                        aria-controls="pills-uniswap"
                        aria-selected="false"
                      >
                        <img
                          src={uniswap}
                          className="img-fluid"
                          alt="uniswap"
                        />
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-pancakeswap"
                      role="tabpanel"
                      aria-labelledby="pills-pancakeswap-tab"
                    >
                      <div className="about-txt">
                        <p className="mb-2">
                         COMING SOON - TBC 
                        </p>
                        <hr />
                        <span className="">
                        <strong>ERC20</strong> Tokens will be available on the Uniswap exchange. Join the Sociallocket community for updates!
                        
                        {/* <div className="mt-5">
                          <div className="tokenomics-button"> 
                          <a href="https://www.canva.com/design/DAFlfYCeFY8/NA0n51RZlpKLgx8OUe16kg/view?utm_content=DAFlfYCeFY8&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink"><strong>Tokenomics</strong></a>
                          </div>
                        </div> */}
                        </span>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-uniswap"
                      role="tabpanel"
                      aria-labelledby="pills-uniswap-tab"
                    >
                      <div className="about-txt">
                        <p className="mb-2">
                        COMING SOON - TBC 
                        </p>
                        <hr />
                        <span className="">
                          <strong>BEP20</strong> Tokens will be available on the Pancakeswap exchange. Join the Sociallocket community for updates!
                         
                          {/* <div className="mt-5">
                            <div className="tokenomics-button">
                              <a href="https://www.canva.com/design/DAFlfYCeFY8/NA0n51RZlpKLgx8OUe16kg/view?utm_content=DAFlfYCeFY8&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink"><strong>Tokenomics</strong></a>
                            </div>
                          </div> */}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 m-auto">
              <div className="text-center">
                <img
                  src={crypto}
                  className="img-fluid"
                  alt="crypto"
                  width="600"
                  height="600"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      {/* bootstrap jquery */}
      {/* bootstrap js */}
      {/* owl js */}
      {/* custom js */}
    </>
  );
};

export default CountdownPage;