import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Header from "../header-footer/header";
import Footer from "../header-footer/footer";
import { useForm } from "react-hook-form";
// import Aboutus from "../assets/images/about-us.png";
import crypto from "../../assets/images/crypto-main.png";
// import Laptop from "../assets/images/laptop.png";
// import pancakeswap from "../assets/images/uniswap.png";
// import uniswap from "../assets/images/pancakeswap.png";
// import Faq from "../assets/images/faq.png";
// import team from "../assets/images/team.jpg";
//import Hamid from "../assets/images/Hamid_Rahman.jpg";
// import Jermaine from "../assets/images/Jermaine_Anderson.jpg";
// import Rishabh from "../assets/images/Rishabh_Rawat.jpg";
import "../../css/style.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { API } from "../../services/ApiClient";
import Swal from "sweetalert2";
import OwlCarousel from 'react-owl-carousel2';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Keys from "../../assets/images/additional/keys-gold.png";
import TenantsImage from "../../assets/images/tenants/tenants.png";
import PropDevImage from "../../assets/images/blogs/zoopla-alt.png";
import LandlordImage from "../../assets/images/blogs/property-management-8.png";
import AgentsImage from "../../assets/images/blogs/cheaper-property-finder.png";

const schema = yup.object({
  email: yup.string().email().required("Email is a required field"),
  name: yup.string().required("Name is a required field"),
  public_key: yup.string().required("Public key is a required field"),
  amount: yup
    .number()
    .typeError("Amount is a required field")
    .positive("Amount must be a positive number"),
});
const Landing = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [success, setSuccess] = useState(false);

  const onSubmit = async (data, event) => {
    event.preventDefault();
    API.post(process.env.REACT_APP_URL + "api/v1/contracts", data)
      .then((response) => {
        setSuccess(true);
        if (response) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Your form has been submitted.",
          });
          reset();
        }
      })
      .catch((error) => {
        if (error && error.response)
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error?.response?.data?.message,
          });
      });
  };
  const options = {
    items: 3,
    nav: false,
    dots:true,
    rewind: true,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    },
  };
  return (
    <>
      <Header />
      <section className="our-team">
          <div className="container">
          <div className="row">
            <div className="banner-size banner-content flex justify-content-center align-items-center m-auto">
              {/* <h1 className="white mb-4">Manage your Property & <span className="col-lg-10 bg-blu mb-2">Tenants</span> <span className="col-lg-10 bg-blu mb-2">without</span> the <span className="col-lg-10 bg-blu mb-2">Stress</span></h1> */}
              {/* <h4 className="col-lg-10 bg-blu mb-2">All the Tools you need to Manage Your Property Today!</h4> */}
              <h1 className="black mb-4 text-center">
                Are you are looking for an {" "}
                <span className="col-lg-10 bg-blu mb-2">apartment,</span> house{" "}
                <span className="col-lg-10 bg-blu mb-2">or room?</span>
              </h1>
              <div className="mt-lg-5 d-flex align-items-center justify-content-center">
                <div className="theme-button">
                  <a href="https://app.sociallocket.com/register">
                    <strong>Sign Up Free</strong>
                  </a>
                </div>
              </div>
            </div>
          </div>
          </div>
        </section>

      <section className="our-team">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="team-head text-center">
              <h3 className=" black mb-2"><strong>The Best</strong> Platform to <strong>Advertise, Manage</strong>  and Find <strong>Properties</strong></h3>

              {/* <h1 className="bg-txt-black text-center mb-4">Our Services</h1> */}

              </div>
            </div>
          </div>
          <div className="row">
          
          <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/how-it-works-agent">
                  <img
                    src={AgentsImage}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                
                <h4 className="mt-3">Agents</h4>
                <p>Read more...</p>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/how-it-works-landlords">
                  <img
                    src={LandlordImage}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">Landlords</h4>

                {/* <h4 className="bg-blu mt-3">Property Developer Portal</h4> */}
                <p>Read more...</p>
              
              </div>
            </div>

            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/how-it-works-tenants">
                  <img
                    src={TenantsImage}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">Tenants</h4>

                {/* <h4 className="bg-blu mt-3">Property Developer Portal</h4> */}
                <p>Read more...</p>
              
              </div>
            </div>

            <div className="col-lg-4">
              <div className="member">
                <div className="">
                <a href="/how-it-works-property-developers">
                  <img
                    src={PropDevImage}
                    className="img-fluid rounded"
                    alt="team"
                    width="400px"
                    height="400"
                  />
                </a>
                </div>
                <h4 className="bg-blu mt-3">Property Developers</h4>

                {/* <h4 className="bg-blu mt-3">Property Developer Portal</h4> */}
                <p>Read more...</p>
              
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="our-team">
          <div className="container">
            <div className="row align-items-center flex-row-reverse">
            <div className="col-lg-6 col-md-6">
                <div className="text-center">
                  <a href="https://app.sociallocket.com/register">
                    <img src={Keys} className="img-fluid" alt="real-estate-property" />
                  </a>
                </div>
              </div>
              
              <div className="col-lg-6 col-md-6">
                <div className="connect-content mb-5">
                  <h2 className="white mb-4"><strong className="bg-txt-black mb-4">Earn</strong><span className="bg-blu"> additional income with your </span><strong className="bg-txt-black mb-4">Property</strong></h2>
                  <div className="mt-5">
                    <div className="theme-button">
                      <a href="https://app.sociallocket.com/register"><strong>Get Started Today</strong></a>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </section>   */}

      <Footer />
      {/* bootstrap jquery */}
      {/* bootstrap js */}
      {/* owl js */}
      {/* custom js */}
    </>
  );
};

export default Landing;