import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Header from "../header-footer/header";
import Footer from "../header-footer/footer";
import blockchain_1 from "../../assets/images/blockchain_1.jpg";
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel2';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';

// import Aboutus from "../../assets/images/about-us.png";

import Aboutus from "../../assets/images/about/about.png";
import Search from "../../assets/images/about/search.png";

import LandlordHappy from "../../assets/images/additional/landlord-happy.png";
import EstateAgent from "../../assets/images/additional/estate-agents.png";
import Keys from "../../assets/images/additional/keys-gold.png";

import Blockchain from "../../assets/images/partners/future-blockchain-summit.jpeg";
import CryptoExpo from "../../assets/images/partners/crypto-expo.png";
import YahooFinance from "../../assets/images/partners/yahoo-finance.jpeg";
import CyberScope from "../../assets/images/partners/cyberscope.jpeg";
import Vincitore from "../../assets/images/partners/vincitore.png";
import Binghatti from "../../assets/images/partners/binghatti.jpeg";
import Danube from "../../assets/images/partners/danube.png";

const options = {
  items: 3,
  nav: false,
  dots:true,
  rewind: true,
  autoplay: true,
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    1024: {
      items: 3,
    },
  },
};

const AboutUs = () => {
    return (
      <div>
        <Header />
        <section className="our-team">
          <div className="container">
            <div className="row align-items-center flex-row-reverse">

              <div className="col-lg-6 col-md-6">
                <div className="text-center">
                  <a href="https://app.sociallocket.com/register">
                    <img src={Aboutus} className="img-fluid" alt="about-us" />
                  </a>
                </div>
              </div>
              
              <div className="col-lg-6 col-md-6">
                <div className="connect-content mb-5">
                  <h1 className="black mb-4"><strong>A New <span className="bg-blu">Home!</span> A New <span className="bg-blu">Smile!</span></strong></h1>
                  <h3 className="black mb-4"><strong>Connecting <span className="bg-blu">Renters</span> with <span className="bg-blu">Agents</span> and <span className="bg-blu">Landlords</span> in a few clicks</strong></h3>
                  <div className="mt-5">
                    <div className="theme-button">
                      <a href="https://app.sociallocket.com/register"><strong>Sign up Now</strong></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="about-us bg-blue">
          <div className="container">
            <div className="row align-items-center flex-row-reverse">
              <div className="col-lg-6 col-md-6">
                <div className="connect-content mb-5">
                  <h2 className="white mb-4"><strong className="bg-txt-black mb-4">Making</strong> it easy for <strong className="bg-txt-black mb-4">renters</strong></h2>
                  <h4 className="bg-txt-black mb-4">
                  <strong>Find the home</strong> you want! <strong>For the price</strong> you want!
                  </h4>
                  <div className="mt-5">
                    <div className="theme-button">
                      <a href="https://app.sociallocket.com/search"><strong>Browse Properties</strong></a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6">
                <div className="text-center">
                  <a href="https://app.sociallocket.com/register">
                    <img src={Search} className="img-fluid" alt="search" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="our-team">
          <div className="container">
            <div className="row align-items-center flex-row-reverse">

              <div className="col-lg-6 col-md-6">
                <div className="text-center">
                  <a href="https://app.sociallocket.com/register">
                    <img src={LandlordHappy} className="img-fluid" alt="landlords" />
                  </a>
                </div>
              </div>
              
              <div className="col-lg-6 col-md-6">
                <div className="connect-content mb-5">
                  <h2 className="white mb-4"><strong className="bg-txt-black mb-4">Making</strong><span className="bg-blu"> it easy for </span><strong className="bg-txt-black mb-4">landlords</strong></h2>
                    <h4 className="bg-txt-black mb-4">
                      <strong>Find the tenant</strong> you want! <strong>Easier</strong> and quicker!
                    </h4>
                  <div className="mt-5">
                    <div className="theme-button">
                      <a href="https://app.sociallocket.com/register"><strong>Post Ad</strong></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="about-us bg-blue">
          <div className="container">
            <div className="row align-items-center flex-row-reverse">
              <div className="col-lg-6 col-md-6">
                <div className="connect-content mb-5">
                  <h2 className="white mb-4"><strong className="bg-txt-black mb-4">Making</strong> it easy for <strong className="bg-txt-black mb-4">agents</strong></h2>
                  <h4 className="bg-txt-black mb-4">
                  <strong>Find the landlords and renters</strong> you want!
                  </h4>
                  <h4 className="bg-txt-black mb-4">
                  <strong>Manage properties the way</strong> you want!
                  </h4>
                  <div className="mt-5">
                    <div className="theme-button">
                      <a href="/pricing"><strong>Agent Sign Up</strong></a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6">
                <div className="text-center">
                  <a href="https://app.sociallocket.com/register">
                    <img src={EstateAgent} className="img-fluid" alt="estate-agents" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>    

        <section className="our-team">
          <div className="container">
            <div className="row align-items-center flex-row-reverse">
            <div className="col-lg-6 col-md-6">
                <div className="text-center">
                  <a href="https://app.sociallocket.com/register">
                    <img src={Keys} className="img-fluid" alt="real-estate-property" />
                  </a>
                </div>
              </div>
              
              <div className="col-lg-6 col-md-6">
                <div className="connect-content mb-5">
                  <h2 className="white mb-4"><strong className="bg-txt-black mb-4">Earn</strong><span className="bg-blu"> additional income with your </span><strong className="bg-txt-black mb-4">property</strong></h2>
                  <div className="mt-5">
                    <div className="theme-button">
                      <a href="https://app.sociallocket.com/register"><strong>Find out more</strong></a>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </section>    

        {/* <section className="our-partner pb-0">
          <div className="container">
            <div className="row">
                <div className="col-lg-12">
                <h1 className="bg-txt-black text-center mb-5"><strong>Brands already earning with <span className="bg-blu">Sociallocket</span></strong></h1>
                  <OwlCarousel options={options}>
                    <div className="item">
                      <a href="https://app.sociallocket.com/register">
                        <img src={YahooFinance} className="img-fluid" alt="client-logos" width={200}  />
                      </a>
                    </div> 
                    <div className="item">
                      <a href="https://app.sociallocket.com/register">
                        <img src={Binghatti} className="img-fluid" alt="client-logos" width={200}  />
                      </a>
                    </div>
                    <div className="item">
                      <a href="https://app.sociallocket.com/register">
                        <img src={Danube} className="img-fluid" alt="client-logos" width={200}  />
                      </a>
                    </div>
                    <div className="item">
                      <a href="https://app.sociallocket.com/register">
                        <img src={Vincitore} className="img-fluid" alt="client-logos" width={200}  />
                      </a>
                    </div>
                    <div className="item">
                      <a href="https://app.sociallocket.com/register">
                        <img src={Blockchain} className="img-fluid" alt="client-logos" width={200}  />
                      </a>
                    </div>
                    <div className="item">
                      <a href="https://app.sociallocket.com/register">
                        <img src={CryptoExpo} className="img-fluid" alt="client-logos" width={200}  />
                      </a>
                    </div>

                    <div className="item">
                      <a href="https://app.sociallocket.com/register">
                        <img src={YahooFinance} className="img-fluid" alt="client-logos" width={200}  />
                      </a>
                    </div> 
                    <div className="item">
                      <a href="https://app.sociallocket.com/register">
                        <img src={Binghatti} className="img-fluid" alt="client-logos" width={200}  />
                      </a>
                    </div>
                    <div className="item">
                      <a href="https://app.sociallocket.com/register">
                        <img src={Danube} className="img-fluid" alt="client-logos" width={200}  />
                      </a>
                    </div>
                    <div className="item">
                      <a href="https://app.sociallocket.com/register">
                        <img src={Vincitore} className="img-fluid" alt="client-logos" width={200}  />
                      </a>
                    </div>
                    <div className="item">
                      <a href="https://app.sociallocket.com/register">
                        <img src={Blockchain} className="img-fluid" alt="client-logos" width={200}  />
                      </a>
                    </div>
                    <div className="item">
                      <a href="https://app.sociallocket.com/register">
                        <img src={CryptoExpo} className="img-fluid" alt="client-logos" width={200}  />
                      </a>
                    </div>

                  </OwlCarousel>
                </div>
            </div>
          </div>
        </section> */}

      {/* <section className="about-us">
        <div className="container">
          <div className="row align-items-center flex-row-reverse">
          <div className="col-lg-6 col-md-6">
              <div className="text-center">
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/YYwGN2OautA?si=Y-NGKE_JjelYIXRk"></iframe>
=              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-us-content mb-5">
                <div className="line-tag">
                  <h5 className="bg-blu mb-3">About Sociallockets Marketplace</h5>
                  <span>&nbsp;</span>
                </div>
                <h2>The Real Estate Marketplace That Helps Everyone Build Wealth</h2>
                <div className="writer-reader">
                  <ul
                    className="nav nav-pills mb-3"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item writer" role="presentation">
                      <button
                        className="nav-link active"
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        From <strong>1%</strong> Ownership
                      </button>
                    </li>
                    <li className="nav-item reader" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      >
                        To <strong>100%</strong> Ownership
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                    >
                      <div className="about-txt">
                        <p className="mb-2">
                        Sociallocket opens the doors of Real Estate to everyone. 
                        We give you the opportunity to invest regardless of your financial circumstance.
                        </p>
                        <hr />
                        <span className="">
                        The Sociallocket platform has changed the way we invest. We provide buyers with the opportunity to create a better future regardless of the credit score, ability to obtain a mortgage or mass amounts of wealth. That’s right! No MORTGAGE needed. No CONTRACTS to sign. And yes, no need to have 1000s in your account.                        </span>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-profile"
                      role="tabpanel"
                      aria-labelledby="pills-profile-tab"
                    >
                      <div className="about-txt">
                        <p className="mb-2">
                        Instead of owning a fraction of an asset, you can own the entire property. From 1%-100%, the choice is yours!                        </p>
                        <hr />
                        <span className="">
                        The Sociallocket platform provides you with the ability to own from 1% to 100% of an asset. You can slowly build your wealth or jump straight in! You invest while we manage the rest. You’ll be informed every step of the way so the worry of how to grow your wealth is over!                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
 
          </div>
        </div>
      </section> */}

        <Footer />
      </div>
      
    );
  }
  
  export default AboutUs;