import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Header from "../../header-footer/header";
import Footer from "../../header-footer/footer";
import { useForm } from "react-hook-form";
import "../../../css/style.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { API } from "../../../services/ApiClient";
import Swal from "sweetalert2";
import OwlCarousel from 'react-owl-carousel2';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Keys from "../../../assets/images/additional/keys-gold.png";
import CheapUKImage from "../../../assets/images/blogs/cheap3.png";
//import Faq from "../../../assets/images/faq.png";

const schema = yup.object({
  email: yup.string().email().required("Email is a required field"),
  name: yup.string().required("Name is a required field"),
  public_key: yup.string().required("Public key is a required field"),
  amount: yup
    .number()
    .typeError("Amount is a required field")
    .positive("Amount must be a positive number"),
});
const BlogCheapPropertiesInTheUK = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [success, setSuccess] = useState(false);

  const onSubmit = async (data, event) => {
    event.preventDefault();
    API.post(process.env.REACT_APP_URL + "api/v1/contracts", data)
      .then((response) => {
        setSuccess(true);
        if (response) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Your form has been submitted.",
          });
          reset();
        }
      })
      .catch((error) => {
        if (error && error.response)
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error?.response?.data?.message,
          });
      });
  };
  const options = {
    items: 3,
    nav: false,
    dots:true,
    rewind: true,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    },
  };
  return (
    <>
      <Header />

        <section className="our-team">
          <div className="container">
          <div className="row">
            <div className="banner-size banner-content flex justify-content-center align-items-center m-auto">
              {/* <h1 className="white mb-4">Manage your Property & <span className="col-lg-10 bg-blu mb-2">Tenants</span> <span className="col-lg-10 bg-blu mb-2">without</span> the <span className="col-lg-10 bg-blu mb-2">Stress</span></h1> */}
              {/* <h4 className="col-lg-10 bg-blu mb-2">All the Tools you need to Manage Your Property Today!</h4> */}
              <h1 className="black mb-4 text-center">
                Still Looking for{" "}
                <span className="col-lg-10 bg-blu mb-2">Cheap Properties in </span> the{" "}
                <span className="col-lg-10 bg-blu mb-2">UK?</span>
              </h1>
              <div className="mt-lg-5 d-flex align-items-center justify-content-center">
                <div className="theme-button">
                  <a href="https://app.sociallocket.com/register">
                    <strong>Sign Up Free</strong>
                  </a>
                </div>
              </div>
            </div>
          </div>
          </div>
        </section>

      <section className="about-us">
        <div className="container">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-lg-6 col-md-6">
                <div className="text-center mt-3">
                    <a href="https://app.sociallocket.com/register">
                        <img src={CheapUKImage} className="img-fluid" alt="CheaperBayutImage" />
                    </a>               
                </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="connect-content mb-5">
              {/* <h5 className="bg-txt-black  mb-4">Date - Jermaine Anderson</h5>

                <p className="bg-txt-black  mb-4">
                In a forever growing market, Dubai’s property prices have seen the highs and lows. In a market which can be quite unpredictable at times, real estate agents, landlords and developers often race to technology and data to forecast future events. 
                </p>

                <p className="bg-txt-black  mb-4">
                With the lowest prices being around the 2008 crash, prices surged following the pandemic as war broke out between Russia and Ukraine alongside economist looking for a place to grow their wealth.
                </p>

                <p className="bg-txt-black  mb-4">
                By filtering through the rubble, we’ve uncovered a number of sweet spots that are still destined for a rise and can be classed as reasonable invests and or homes to buy for you and your family.
                </p>

                <p className="bg-txt-black  mb-4">
                Using both historical and current data, the <strong>Sociallocket</strong> team have discovered that there are a number of areas that still have cheap properties in comparison to some of the well-known developed areas. If your looking for your next investment or a new home for you and your family, the following areas may be of interest to you:
                </p>

                <p className="bg-txt-black  mb-4">
                    <li><strong>Meydan</strong></li>
                    <li>Jebel Ali</li>
                    <li>Arjan</li>
                    <li>JVC</li>
                    <li>Al Furjan</li>
                    <li>Al Jaddaf</li>
                    <li>Sports City</li>
                </p>

                <p className="bg-txt-black  mb-4">
                In just naming few of Dubai’s sweet spots for cheaper housing, you can take advantage of our software, by submitting your requests where our team alongside real estate agents, landlords and developers will be able to assist you or source the right property for you.
                </p>


                <p className="bg-txt-black  mb-4">
                At <strong>Sociallocket</strong>, we’re constantly striving to be the best with our all-in-one solution which is opening the doors to a better way of investing and renting.
                </p>

                <p className="bg-txt-black  mb-4">
                With features consisting of:
                </p>

                <p className="bg-txt-black  mb-4">
                    <li><strong>Free Property Advertisement</strong></li>
                    <li>Instant Chat (Both private and public)</li>
                    <li>Property Management</li>
                    <li>Customer Relationship Management</li>
                    <li>Seamless Integrations</li>
                    <li>Portfolio Tracking</li>
                    <li>Payment Gateway (yes, that’s right! A payment gateway)</li>
                    <li>And many more</li>
                </p>

                <p className="bg-txt-black  mb-4">
                If you’re a first-time buyer, serial investor, landlord or someone wanting to sell their real estate property or portfolio <strong>sign up</strong> to sociallocket.com <strong>today</strong> and get started.
                We have a number of <strong>real estate agents</strong>, <strong>landlords</strong>, <strong>property developers</strong> waiting to help. 
                </p>

                <p className="bg-txt-black  mb-4">
                Don’t be the last to hear about that hot new deal, sign up to sociallocket.com today. It’s <strong>FREE</strong> and <strong>EASY</strong>.
                </p> */}

                <div className="mt-5">
                  <div className="theme-button">
                    <a href="https://app.sociallocket.com/register"><strong>Get Started</strong></a>
                  </div>
                </div>
                
              </div>
            </div>
            
          </div>
        </div>
      </section>

      <section className="our-team">
          <div className="container">
            <div className="row align-items-center flex-row-reverse">
            <div className="col-lg-6 col-md-6">
                <div className="text-center">
                  <a href="https://app.sociallocket.com/register">
                    <img src={Keys} className="img-fluid" alt="real-estate-property" />
                  </a>
                </div>
              </div>
              
              <div className="col-lg-6 col-md-6">
                <div className="connect-content mb-5">
                  <h2 className="white mb-4"><strong className="bg-txt-black mb-4">Earn</strong><span className="bg-blu"> additional income with your </span><strong className="bg-txt-black mb-4">Property</strong></h2>
                  <div className="mt-5">
                    <div className="theme-button">
                      <a href="https://app.sociallocket.com/register"><strong>Find out more</strong></a>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </section>  

      <Footer />
      {/* bootstrap jquery */}
      {/* bootstrap js */}
      {/* owl js */}
      {/* custom js */}
    </>
  );
};

export default BlogCheapPropertiesInTheUK;