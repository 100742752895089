import React, { useEffect, useState } from "react";

const ContactUsComponent = () => {
  const targetDate = new Date('April 05, 2024 00:00:00');
  const [timeLeft, setTimeLeft] = useState(Math.floor((targetDate - new Date()) / 1000));

// const CountdownTimer = (props) => {
//   const [timeLeft, setTimeLeft] = useState(props.duration);

//   useEffect(() => {
//     const timer = setInterval(() => {
//       setTimeLeft((prevTime) => prevTime - 1);
//     }, 1000);

//     return () => clearInterval(timer);
//   }, []);

// const CountdownTimer = () => {
//   const targetDate = new Date("May 12, 2023 00:00:00");
//   const [timeLeft, setTimeLeft] = useState(
//     Math.floor((targetDate - new Date()) / 1000)
//   );

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const days = Math.floor(timeLeft / (60 * 60 * 24));
  const hours = Math.floor((timeLeft / (60 * 60)) % 24);
  const minutes = Math.floor((timeLeft / 60) % 60);
  const seconds = Math.floor(timeLeft % 60);
  return (
    <div className="count text-center">
      <h1 id="headline"><strong>We would <strong className="bg-blu">Love</strong> to <strong className="bg-blu">hear</strong> from you</strong></h1>
      <div id="countdown">
        <div id="countdown">
          {/* <h4>Feel free to email us: <a className="" href="mailto:team@sociallocket.com?subject=Message to the Sociallocket Team">team@sociallocket.com</a></h4>
          <h4>Or</h4> */}
          {/* <h4>Contact Sales: <strong><a className="nav-item" href="tel:+4402034881143">+44 (0) 203 488 1143</a></strong></h4> */}
          <h4 class="nav-item">Feel <strong>free</strong> to <strong>contact</strong> our team<li class="nav-item">
                            <strong>
                                <a className="nav-link" href="tel:+4402034881143">
                                    +44 (0) 203 488 1143
                                </a>
                            </strong>
                        </li></h4>


        </div>
        <div id="countdown">
        </div>
      </div>
      <div id="content" className="golive">
        <span>Test</span>
      </div>
    </div>
  );
};

export default ContactUsComponent;
