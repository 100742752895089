import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Header from "../../header-footer/header";
import Footer from "../../header-footer/footer";
import { useForm } from "react-hook-form";
import "../../../css/style.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { API } from "../../../services/ApiClient";
import Swal from "sweetalert2";
import OwlCarousel from 'react-owl-carousel2';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Keys from "../../../assets/images/additional/keys-gold.png";
import RightmoveAltImage from "../../../assets/images/blogs/rightmove-alt.png";
// import AnimatedLogo from "../../../assets/images/blue-logo-white-bg.gif";
//import Faq from "../../../assets/images/faq.png";

const schema = yup.object({
  email: yup.string().email().required("Email is a required field"),
  name: yup.string().required("Name is a required field"),
  public_key: yup.string().required("Public key is a required field"),
  amount: yup
    .number()
    .typeError("Amount is a required field")
    .positive("Amount must be a positive number"),
});
const BlogCheaperAlternativeToRightMove = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [success, setSuccess] = useState(false);

  const onSubmit = async (data, event) => {
    event.preventDefault();
    API.post(process.env.REACT_APP_URL + "api/v1/contracts", data)
      .then((response) => {
        setSuccess(true);
        if (response) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Your form has been submitted.",
          });
          reset();
        }
      })
      .catch((error) => {
        if (error && error.response)
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error?.response?.data?.message,
          });
      });
  };
  const options = {
    items: 3,
    nav: false,
    dots:true,
    rewind: true,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    },
  };
  return (
    <>
      <Header />

        <section className="our-team">
          <div className="container">
          <div className="row">
            <div className="banner-size banner-content flex justify-content-center align-items-center m-auto">
              {/* <h1 className="white mb-4">Manage your Property & <span className="col-lg-10 bg-blu mb-2">Tenants</span> <span className="col-lg-10 bg-blu mb-2">without</span> the <span className="col-lg-10 bg-blu mb-2">Stress</span></h1> */}
              {/* <h4 className="col-lg-10 bg-blu mb-2">All the Tools you need to Manage Your Property Today!</h4> */}
              <h1 className="black mb-4 text-center">
                Is Sociallocket an{" "}
                <span className="col-lg-10 bg-blu mb-2">Alternative </span>to{" "}
                <span className="col-lg-10 bg-blu mb-2">Rightmove</span>
              </h1>

              <div className="mt-lg-5 d-flex align-items-center justify-content-center">
                <div className="theme-button">
                  <a href="https://app.sociallocket.com/register">
                    <strong>Sign Up Free</strong>
                  </a>
                </div>
              </div>
            </div>
          </div>
          </div>
        </section>

      <section className="about-us">
        <div className="container">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-lg-6 col-md-6">
              <div className="text-center mt-3">
                <a href="https://app.sociallocket.com/register">
                    <img src={RightmoveAltImage} className="img-fluid" alt="RightmoveAltImage" />
                </a>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="connect-content mb-5">
                <h5 className="bg-txt-black  mb-4">Date - Jermaine Anderson</h5>

                <p className="bg-txt-black  mb-4">
                In a saturated real estate market, Rightmove have referred to themselves as “The UK’s number one property website”. Although this statement could be deemed as true, Rightmove definitely do hold a significant share of the market and can refer to themselves as one of the market leaders. 
                </p>

                <p className="bg-txt-black  mb-4">
                In a somewhat twist, not everyone sees it that way as many flood to Facebook to join a group called “Boycott Rightmove!! - Estate Agents UK”. Here many have joined forces to argue against the high and outrages pricing model Rightmove have imposed on its users. 
                </p>

                <p className="bg-txt-black  mb-4">
                A well know real estate agency owner recently took to the internet in an attempt to redeem the industry claiming she now pays £1,200 a month for a basic Rightmove package with extras on top – amounting to more than the rent of her city office. 
                </p>

                <p className="bg-txt-black  mb-4">

                That’s not all as users on the Money Saving Experts forum referring to the Rightmove’s experience as “sub par” and others online raging about:
                </p>

                <p className="bg-txt-black  mb-4">
                  <li>High subscription pricing</li>
                  <li>Features not working</li>
                  <li>The lack of support from customer service</li>
                  <li>Outages</li>

                  <li>Listings not showing</li>
                  <li>Not responding to complaints (nor fixing issues reported)</li>
                </p>

                <p className="bg-txt-black  mb-4">
                Not to worry, the Robin Hood of the real estate industry is here. 
                </p>

                <p className="bg-txt-black  mb-4">
                Striving to rescue relators, Sociallocket is a unique property portal designed to streamline the acquisitions and rentals of real estate assets (Apartments, Houses, Bungalows, Commercial Buildings, Rooms and many more).
                Sociallocket is designed to connect renters (tenants) with real estate agents and landlords as part of bringing the joy back into our homes.
                </p>

                <p className="bg-txt-black  mb-4">
                With WhatsApp like features and a Shopify style approach, Sociallocket is paving the way and removing stress from property investing and renting.
                </p>

                <p className="bg-txt-black  mb-4">
                We are far from getting started but we take pride in the Sociallocket platform as it’s making things easier for agents, landlords, renters and even real estate (property) developers by providing the tools and apps they need.
                </p>

                <p className="bg-txt-black  mb-4">
                  <li>Free Property Advertisement (self-serve)</li>
                  <li>Instant Chat (Both private and public)</li>
                  <li>Property Management</li>
                  <li>Customer Relationship Management</li>

                  <li>Portfolio Tracking</li>
                  <li>Payment Gateway (yes, that’s right! A payment gateway)</li>
                  <li>And many more</li>
                </p>

                <p className="bg-txt-black  mb-4">
                Now do you see why we’re a better alternative? Minus the traffic, daily active users and number of listings Sociallocket has in comparison to Zoopla, Sociallocket is on the rise and growing rapidly.
                </p>

                <p className="bg-txt-black  mb-4">
                If you’re a real estate agent, landlord, property developer (big or small) or someone looking for your new home, sign up to sociallocket.com today and get started. It’s FREE and EASY
                </p>

                <div className="mt-5">
                  <div className="theme-button">
                    <a href="https://app.sociallocket.com/register"><strong>Get Started</strong></a>
                  </div>
                </div>

                
              </div>
            </div>
            
          </div>
        </div>
      </section>

      <section className="our-team">
          <div className="container">
            <div className="row align-items-center flex-row-reverse">
            <div className="col-lg-6 col-md-6">
                <div className="text-center">
                  <a href="https://app.sociallocket.com/register">
                    <img src={Keys} className="img-fluid" alt="real-estate-property" />
                  </a>
                </div>
              </div>
              
              <div className="col-lg-6 col-md-6">
                <div className="connect-content mb-5">
                  <h2 className="white mb-4"><strong className="bg-txt-black mb-4">Earn</strong><span className="bg-blu"> additional income with your </span><strong className="bg-txt-black mb-4">Property</strong></h2>
                  <div className="mt-5">
                    <div className="theme-button">
                      <a href="https://app.sociallocket.com/register"><strong>Find out more</strong></a>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </section>  

      <Footer />
      {/* bootstrap jquery */}
      {/* bootstrap js */}
      {/* owl js */}
      {/* custom js */}
    </>
  );
};

export default BlogCheaperAlternativeToRightMove;