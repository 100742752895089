import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Header from "./header-footer/header";
import Footer from "./header-footer/footer";
import { useForm } from "react-hook-form";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Aboutus from "../assets/images/about-us.png";
import crypto from "../assets/images/crypto-main.png";
import Laptop from "../assets/images/laptop.png";
import pancakeswap from "../assets/images/uniswap.png";
import uniswap from "../assets/images/pancakeswap.png";
import Faq from "../assets/images/faq.png";
// import team from "../assets/images/team.jpg";
//import Hamid from "../assets/images/Hamid_Rahman.jpg";
import Jermaine from "../assets/images/Jermaine_Anderson.jpg";
import Rishabh from "../assets/images/Rishabh_Rawat.jpg";
import "../css/style.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { API } from "../services/ApiClient";
import Swal from "sweetalert2";
import OwlCarousel from "react-owl-carousel2";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// import DoesQa from "../assets/images/does-qa.png";
// import CyberScope from "../assets/images/partners/cyberscope.jpeg";
// import Blockchain from "../assets/images/future-blockchain-summit.jpeg";
// import CryptoExpo from "../assets/images/crypto-expo.png";
// import YahooFinance from "../assets/images/yahoo-finance.jpeg";

import Advertise from "../assets/images/advertise/new-advertise.png";
import Chat from "../assets/images/chat/chat.png";
import AssetManagement from "../assets/images/asset-management/property-management.png";
import PortfolioManagement from "../assets/images/portfolio-management/portfolio-management.png";
import CRM from "../assets/images/crm/crm.png";
import payments from "../assets/images/payments/payments.png";
import Landlords from "../assets/images/landlords/landlords-1.png";
import Tenants from "../assets/images/tenants/tenants.png";
import Earn from "../assets/images/earn/earn-1.png";
import Demo from "../assets/images/demo/demo-1.png";

import Developer from "../assets/images/developer/developer-3-main.jpg";

import Blockchain from "../assets/images/partners/future-blockchain-summit.jpeg";
import CryptoExpo from "../assets/images/partners/crypto-expo.png";
import YahooFinance from "../assets/images/partners/yahoo-finance.jpeg";
import CyberScope from "../assets/images/partners/cyberscope.jpeg";
import Vincitore from "../assets/images/partners/vincitore.png";
import Binghatti from "../assets/images/partners/binghatti.jpeg";
import Danube from "../assets/images/partners/danube.png";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const schema = yup.object({
  email: yup.string().email().required("Email is a required"),
  name: yup.string().required("Name is a required"),
  public_key: yup.string().required("Contact Number is a required"),
  amount: yup
    .number()
    .typeError("Message is a required")
    .positive("Message is a required"),
});
const Landing = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [keyword, setKeyword] = useState("");

  const onSubmit = async (data, event) => {
    event.preventDefault();
    API.post(process.env.REACT_APP_URL + "api/v1/contracts", data)
      .then((response) => {
        setSuccess(true);
        if (response) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Your form has been submitted.",
          });
          reset();
        }
      })
      .catch((error) => {
        if (error && error.response)
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error?.response?.data?.message,
          });
      });
  };
  const options = {
    items: 3,
    nav: false,
    dots: true,
    rewind: true,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    },
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  const submitHandler = (e) => {
    e.preventDefault();
    // window.location.href = "https://app.sociallocket.com/search/";
    // const url = `https://app.sociallocket.com/search?keyword=${keyword}`;
    //const url = `http://localhost:3001/search?keyword=${keyword.toLocaleLowerCase()}`;
    const url = `${process.env.REACT_APP_URL}search?keyword=${keyword.toLocaleLowerCase()}`;
    window.open(url, "_blank");
  };

  return (
    <>
      <Header />
      <section className="banner">
        <div className="container">
          <div className="row">
            <div className="banner-size banner-content flex justify-content-center align-items-center m-auto">
              {/* <h1 className="white mb-4">Manage your Property & <span className="col-lg-10 bg-blu mb-2">Tenants</span> <span className="col-lg-10 bg-blu mb-2">without</span> the <span className="col-lg-10 bg-blu mb-2">Stress</span></h1> */}
              {/* <h4 className="col-lg-10 bg-blu mb-2">All the Tools you need to Manage Your Property Today!</h4> */}
              {/* <h1 className="white mb-4 text-center">
                Looking for a apartment, house or room.....{" "}
                <span className="col-lg-10 bg-blu mb-2"></span>{" "}
                <span className="col-lg-10 bg-blu mb-2"></span> {" "}
                <span className="col-lg-10 bg-blu mb-2"></span>
              </h1> */}
              <h1 className="white mb-4 text-center">
                Your new {" "}
                <span className="col-lg-10 white mb-2">home is just a few</span>{" "}
                <span className="col-lg-10 bg-blu mb-2"></span> {" "}
                <span className="col-lg-10 white mb-2">clicks away </span>{" "}
                <span className="col-lg-10 bg-blu mb-2"></span>
              </h1>
              <h4 className="white mb-2 text-center">
                {/* <strong>The</strong> search for your new home stops <strong className="col-lg-10 bg-blu">today</strong>{" "} */}
              </h4>
              <p className=" col-lg-9 white mb-4">
                {/* <strong>Sociallocket</strong>, the blockchain that connects <strong>buyers, sellers</strong> and <strong>renters</strong> together. Designed to <strong>streamline your acquisitions</strong> and <strong>rentals</strong>, <strong>saving</strong> you <strong>time</strong> and <strong>money</strong>. */}
              </p>

              <div className="mt-lg-5 d-flex align-items-center justify-content-center">
                {/* <div className="theme-button">
                  <a href="https://app.sociallocket.com/register">
                    <strong>Sign up Free</strong>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div style={{ marginTop: "50px" }}>
              <div className="search-panel">
                <Tabs>
                  <TabList className="m-0 p-0">
                    <div className="tablist">
                      <Tab className="tab">for sale</Tab>
                      <Tab className="tab">for rent</Tab>
                      {/* <Tab className="tab">tenants</Tab> */}
                    </div>
                  </TabList>
                  <div className="">
                    <TabPanel>
                      <div className="tab_panel_container">
                        <h3 className="panel_title">Enter a location</h3>
                        <form
                          className="search_container"
                          onSubmit={submitHandler}
                        >
                          <input
                            placeholder="e.g. 'London', 'Birmingham' or 'B37'"
                            type="text"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                          />
                          <button type="submit">
                            <span>
                              <FaSearch />
                            </span>
                            <span>search</span>
                          </button>
                        </form>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="tab_panel_container">
                        <h3 className="panel_title">Enter a location</h3>
                        <form
                          className="search_container"
                          onSubmit={submitHandler}
                        >
                          <input
                            placeholder="e.g. 'London', 'Birmingham' or 'B37'"
                            type="text"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                          />
                          <button type="submit">
                            <span>
                              <FaSearch />
                            </span>
                            <span>search</span>
                          </button>
                        </form>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className="tab_panel_container">
                        <h3 className="panel_title">Enter a location</h3>
                        <form
                          className="search_container"
                          onSubmit={submitHandler}
                        >
                          <input
                            placeholder="e.g. 'London', 'Birmingham' or 'B37'"
                            type="text"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                          />
                          <button type="submit">
                            <span>
                              <FaSearch />
                            </span>
                            <span>search</span>
                          </button>
                        </form>
                      </div>
                    </TabPanel>
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-us">
        <div className="container">
          <div className="row align-items-center flex-row-reverse">
            {/* <div className="col-lg-6 col-md-6">
              <div className="text-center">
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/YYwGN2OautA"></iframe>
              </div>
            </div> */}

            <div className="col-lg-6 col-md-6">
              <div className="text-center">
                <a href="https://app.sociallocket.com/register">
                  <img src={Demo} className="img-fluid" alt="demo" />
                </a>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="connect-content mb-5">
                {/* <h2 className="bg-txt-black mb-4">Sell your Property Today</h2> */}
                <h2 className="bg-txt-black mb-4">
                  <strong className="bg-blu">Advertise</strong> and{" "}
                  <strong className="bg-blu">manage</strong> your property today
                </h2>
                <h4 className="bg-txt-black mb-4">
                  See how <strong>Sociallocket</strong> can save you{" "}
                  <strong className="bg-blu">time</strong> and{" "}
                  <strong className="bg-blu">money</strong> whilst instilling{" "}
                  <strong className="bg-blu">trust</strong> and{" "}
                  <strong className="bg-blu">confidence!</strong>
                </h4>
                <div className="mt-5">
                  <div className="theme-button">
                    <a href="https://app.sociallocket.com/register">
                      <strong>Advertise for Free</strong>
                    </a>
                    {/* <a href="https://calendly.com/sociallocket"><strong>Book A Demo</strong></a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="Connect-with">
        <div className="container">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-lg-6 col-md-6">
              <div className="connect-content mb-5">
                <h2 className="white mb-4">
                  <strong className="bg-blu">Estate agencies</strong> love using
                  Sociallocket
                </h2>
                <h4 className="white mb-4">
                  <strong className="bg-blu">Sign up</strong> to{" "}
                  <strong>Sociallocket</strong> for <strong>FREE</strong>. We
                  made it <strong>easy</strong>.
                </h4>
                <div className="mt-5">
                  <div className="theme-button">
                    <a href="https://app.sociallocket.com/register">
                      <strong>Sign up Free</strong>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="text-center">
                <a href="https://app.sociallocket.com/register">
                  <img src={Laptop} className="img-fluid" alt="laptop" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-us">
        <div className="container">
          <div className="row align-items-center flex-row-reverse">
            {/* <div className="col-lg-6 col-md-6">
              <div className="text-center">
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/YYwGN2OautA"></iframe>
              </div>
            </div> */}
            <div className="col-lg-6 col-md-6">
              <div className="text-center">
                <a href="https://app.sociallocket.com/register">
                  <img src={Landlords} className="img-fluid" alt="landlords" />
                </a>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="connect-content mb-5">
                {/* <h2 className="bg-txt-black mb-4">Sell your Property Today</h2> */}
                <h2 className="bg-txt-black mb-4">
                  <strong className="bg-blu">Landlords</strong> go{" "}
                  <strong className="bg-blu">crazy</strong> without{" "}
                  <strong className="bg-blu">Sociallocket</strong>
                </h2>
                {/* <h4 className="bg-txt-black mb-4">
                No more <strong>sleepless</strong> nights!
                </h4> */}
                <h4 className="bg-txt-black mb-4">
                  <strong>Manage</strong> your{" "}
                  <strong>tenants and properties</strong> with{" "}
                  <strong className="bg-blu">ease</strong>
                </h4>
                <div className="mt-5">
                  <div className="theme-button">
                    <a href="https://app.sociallocket.com/register">
                      <strong>Sign up Now</strong>
                    </a>
                    {/* <a href="https://calendly.com/sociallocket"><strong>Book A Demo</strong></a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-us bg-blue">
        <div className="container">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-lg-6 col-md-6">
              <div className="connect-content mb-5">
                <h2 className="white mb-4">
                  <strong className="bg-txt-black mb-4">Tenants</strong> can't
                  live without{" "}
                  <strong className="bg-txt-black mb-4">Sociallocket</strong>
                </h2>
                <h4 className="bg-txt-black mb-4">
                  <strong>Everything tenants</strong> need under one roof!
                </h4>
                <div className="mt-5">
                  <div className="theme-button">
                    <a href="https://app.sociallocket.com/register">
                      <strong>Sign up Today</strong>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-6 col-md-6">
              <div className="text-center">
                <img src={Laptop} className="img-fluid" alt="laptop" />
              </div>
            </div> */}
            <div className="col-lg-6 col-md-6">
              <div className="text-center">
                <a href="https://app.sociallocket.com/register">
                  <img src={Tenants} className="img-fluid" alt="tenants" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our-team">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="team-head text-center">
              <h3 className="bg-blu mb-2">Just a few of the benifits the <strong className="bg-txt-black mb-2">Sociallocket</strong> platform provides</h3>
              {/* <h5 className="bg-blu mb-2 text-center">Change the way you Interact with <strong>Property</strong> Forever</h5> */}
                <p className=" m-auto">
                  {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Quis ipsum suspendisse. */}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="member">
                <div className="">
                  <a href="/services/advertise">
                    <img
                      src={Advertise}
                      className="img-fluid rounded"
                      alt="team"
                      width="400px"
                      height="400"
                    />
                  </a>
                </div>

                <h4 className="text-center mt-3">Advertise Your Room or Property</h4>
                <p className="text-center mt-3">
                  Agents and landlords advertise for{" "}
                  <strong className="bg-blu">FREE!</strong>
                </p>
                {/* <div className="text-center mt-lg-5">
                  <div className="theme-button">
                    <a href="https://app.sociallocket.com/register"><strong>Get Started Today</strong></a>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="member">
                <div className="">
                  <a href="/services/tools-and-apps">
                    <img
                      src={Chat}
                      className="img-fluid rounded"
                      alt="team"
                      width="400px"
                      height="400"
                    />
                  </a>
                </div>
                <h4 className="text-center bg-blu mt-3">Instant Chat</h4>
                <p className="text-center mt-3">
                  Tenants connect with agents and landlords{" "}
                  <strong className="bg-blu">instantly</strong>
                </p>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="member">
                <div className="">
                  <a href="/services/property-management">
                    <img
                      src={AssetManagement}
                      className="img-fluid rounded"
                      alt="team"
                      width="400px"
                      height="400"
                    />
                  </a>
                </div>
                <h4 className="text-center mt-3">Property Management</h4>
                <p className="text-center mt-3">
                  Manage propertys and portfolios with{" "}
                  <strong className="bg-blu">ease</strong>
                </p>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="member">
                <div className="">
                  <a href="/services/customer-relationship-management">
                    <img
                      src={CRM}
                      className="img-fluid rounded"
                      alt="team"
                      width="400px"
                      height="400"
                    />
                  </a>
                </div>
                <h4 className="text-center mt-3">
                  Customer Relationship Management
                </h4>
                <p className="text-center mt-3">
                  Building better relationships with landlords, tenants and{" "}
                  <strong className="bg-blu">agents</strong>
                </p>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="member">
                <div className="">
                  <a href="/services/portfolio-tracking">
                    <img
                      src={PortfolioManagement}
                      className="img-fluid rounded"
                      alt="team"
                      width="400px"
                      height="400"
                    />
                  </a>
                </div>
                <h4 className="text-center mt-3">Portfolio Tracking</h4>
                <p className="text-center mt-3">
                  In-app real-time statistics to help whilst on the{" "}
                  <strong className="bg-blu">go</strong>
                </p>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="member">
                <div className="">
                  <a href="/services/payment-gateway">
                    <img
                      src={payments}
                      className="img-fluid rounded"
                      alt="team"
                      width="400px"
                      height="400"
                    />
                  </a>
                </div>

                <h4 className="text-center mt-3">Payment Gateway</h4>
                <p className="text-center mt-3">
                  Secure In-app <strong className="bg-blu">payments</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
                
      {/* <section> 
      <h1 className="text-center mb-5"><strong>Better</strong> & <strong>Cheaper</strong> than all the <strong className="bg-blu mt-3">Rest</strong></h1>
          <stripe-pricing-table pricing-table-id="prctbl_1P1V3nK7tBDZInmpovkFWxpG"
          publishable-key="pk_live_51MFOETK7tBDZInmpEFjUloWG0GP0n6jLqJ2qFarNpwfE2Sc4BZS88nnoW8gRNX7wpu6fyNRIjl7vgN8Rn4VCQZj8002XCsfJfc">
          </stripe-pricing-table>

          <stripe-pricing-table pricing-table-id="prctbl_1OvxZvK7tBDZInmpNvYL7Fdg"
          publishable-key="pk_live_51MFOETK7tBDZInmpEFjUloWG0GP0n6jLqJ2qFarNpwfE2Sc4BZS88nnoW8gRNX7wpu6fyNRIjl7vgN8Rn4VCQZj8002XCsfJfc">
          </stripe-pricing-table>

          <stripe-pricing-table pricing-table-id="prctbl_1PAM5uK7tBDZInmpdTkNryVi"
          publishable-key="pk_live_51MFOETK7tBDZInmpEFjUloWG0GP0n6jLqJ2qFarNpwfE2Sc4BZS88nnoW8gRNX7wpu6fyNRIjl7vgN8Rn4VCQZj8002XCsfJfc">
          </stripe-pricing-table>

      </section> */}

      <section className="about-us bg-blue">
        <div className="container">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-lg-6 col-md-6">
              <div className="text-center">
                <a href="https://app.sociallocket.com/register">
                  <img src={Earn} className="img-fluid" alt="tenants" />
                </a>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="connect-content mb-5">
                <h2 className="white mb-4">
                  <strong className="bg-txt-black mb-4">Earn</strong> additional
                  income with your{" "}
                  <strong className="bg-txt-black mb-4">property</strong>
                </h2>
                <div className="mt-5">
                  <div className="theme-button">
                    <a href="https://app.sociallocket.com/register">
                      <strong>Find out more</strong>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our-partner pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/* <h1 className="bg-txt-black text-center mb-5"><strong>Some of our many <span className="bg-blu">Partners</span></strong></h1> */}
              <h1 className="bg-txt-black text-center mb-5">
                <strong>
                  Brands already earning with{" "}
                  <span className="bg-blu">Sociallocket</span>
                </strong>
              </h1>
              <OwlCarousel options={options}>
                <div className="item">
                  <a href="https://app.sociallocket.com/register">
                    <img
                      src={YahooFinance}
                      className="img-fluid"
                      alt="client-logos"
                      width={200}
                    />
                  </a>
                </div>
                <div className="item">
                  <a href="https://app.sociallocket.com/register">
                    <img
                      src={Binghatti}
                      className="img-fluid"
                      alt="client-logos"
                      width={200}
                    />
                  </a>
                </div>
                <div className="item">
                  <a href="https://app.sociallocket.com/register">
                    <img
                      src={Danube}
                      className="img-fluid"
                      alt="client-logos"
                      width={200}
                    />
                  </a>
                </div>
                <div className="item">
                  <a href="https://app.sociallocket.com/register">
                    <img
                      src={Vincitore}
                      className="img-fluid"
                      alt="client-logos"
                      width={200}
                    />
                  </a>
                </div>
                <div className="item">
                  <a href="https://app.sociallocket.com/register">
                    <img
                      src={Blockchain}
                      className="img-fluid"
                      alt="client-logos"
                      width={200}
                    />
                  </a>
                </div>
                <div className="item">
                  <a href="https://app.sociallocket.com/register">
                    <img
                      src={CryptoExpo}
                      className="img-fluid"
                      alt="client-logos"
                      width={200}
                    />
                  </a>
                </div>
                {/* <div className="item">
                      <img src={CyberScope} className="img-fluid" alt="client-logos" width={200}  />
                  </div> */}
                {/* <div className="item">
                      <img src={DoesQa} className="img-fluid" alt="client-logos" width={200}  />
                  </div> */}

                <div className="item">
                  <a href="https://app.sociallocket.com/register">
                    <img
                      src={YahooFinance}
                      className="img-fluid"
                      alt="client-logos"
                      width={200}
                    />
                  </a>
                </div>
                <div className="item">
                  <a href="https://app.sociallocket.com/register">
                    <img
                      src={Binghatti}
                      className="img-fluid"
                      alt="client-logos"
                      width={200}
                    />
                  </a>
                </div>
                <div className="item">
                  <a href="https://app.sociallocket.com/register">
                    <img
                      src={Danube}
                      className="img-fluid"
                      alt="client-logos"
                      width={200}
                    />
                  </a>
                </div>
                <div className="item">
                  <a href="https://app.sociallocket.com/register">
                    <img
                      src={Vincitore}
                      className="img-fluid"
                      alt="client-logos"
                      width={200}
                    />
                  </a>
                </div>
                <div className="item">
                  <a href="https://app.sociallocket.com/register">
                    <img
                      src={Blockchain}
                      className="img-fluid"
                      alt="client-logos"
                      width={200}
                    />
                  </a>
                </div>
                <div className="item">
                  <a href="https://app.sociallocket.com/register">
                    <img
                      src={CryptoExpo}
                      className="img-fluid"
                      alt="client-logos"
                      width={200}
                    />
                  </a>
                </div>
                {/* <div className="item">
                      <img src={CyberScope} className="img-fluid" alt="client-logos" width={200}  />
                  </div> */}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="map-form">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="login-box">
                <div className="text-center">
                  
                  <h2><strong>Buy</strong>, <strong>Sell</strong> and <strong>Rent </strong>with <strong>Confidence</strong></h2>
                  <h4 className="white mb-3 m-auto">
                    <strong >We Are Here To Help!</strong> A member of our team is waiting to assist you!
                  </h4>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="user-box">
                    <div class="position-relative">
                      <input
                        type="text"
                        name="name"
                        {...register("name")}
                        required=""
                      />
                      <label>Full Name*</label>
                    </div>
                    {errors.name?.message ? (
                      <div className="alert alert-danger" role="alert">
                        {errors?.name?.message}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="user-box">
                    <div class="position-relative">
                      <input
                        type="email"
                        name="email"
                        {...register("email")}
                        required=""
                      />
                      <label>Email Id*</label>
                    </div>
                    {errors.email?.message ? (
                      <div className="alert alert-danger" role="alert">
                        {errors?.email?.message}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="user-box">
                    <div class="position-relative">
                      <input
                        type="text"
                        name="public_key"
                        {...register("public_key")}
                        required=""
                      />
                      <label>Contract Number*</label>
                    </div>
                    {errors.public_key?.message ? (
                      <div className="alert alert-danger" role="alert">
                        {errors?.public_key?.message}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="user-box">
                    <div class="position-relative">
                      <input
                        type="text"
                        name="amount"
                        {...register("amount", {
                          //valueAsNumber: true,
                        })}
                        required=""
                      />
                      <label>Message*</label>
                    </div>
                    {errors.amount?.message ? (
                      <div className="alert alert-danger" role="alert">
                        {errors?.amount?.message}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="text-center">
                    <button type="submit">
                      <span />
                      <span />
                      <span />
                      <span />
                      Enquire
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <Footer />
      {/* bootstrap jquery */}
      {/* bootstrap js */}
      {/* owl js */}
      {/* custom js */}
    </>
  );
};

export default Landing;
