import React, { useEffect } from "react";
import "./index.css";
import { RxCross2 } from "react-icons/rx";
import adsPhoto from "../../assets/ads.png";
import frameHouse from "../../assets/frame-house.png";
import { Link } from "react-router-dom";
const AddsModal = ({ modal, setModal }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div className="box-modal">
      <div className="box">
        <div className="box-header">
          <span className="close-btn" onClick={() => setModal(!modal)}>
            <RxCross2 />
          </span>
        </div>

        <div className="modal-body">
          <h3>Please select the type of Ad</h3>
          <div className="card-container">
            <div className="ad-card">
              <img src={adsPhoto} alt="ads-photo" />
              <div className="ad-card-content">
                <h5>Offer property / room</h5>
                <p>
                  Advertise your property to millions of potential buyers and
                  renters.
                </p>
              </div>
              <Link to={`${process.env.REACT_APP_URL}create-post`} target="_blank" className="ad-card-btn">register</Link>
            </div>
            <div className="ad-card">
              <img src={frameHouse} alt="ads-photo" />
              <div className="ad-card-content">
                <h5>Offer property / room</h5>
                <p>
                  Advertise your property to millions of potential buyers and
                  renters.
                </p>
              </div>
              <Link to={`${process.env.REACT_APP_URL}create-post`} target="_blank" className="ad-card-btn">register</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddsModal;
