import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Header from "../../header-footer/header";
import Footer from "../../header-footer/footer";
import { useForm } from "react-hook-form";
import "../../../css/style.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { API } from "../../../services/ApiClient";
import Swal from "sweetalert2";
import OwlCarousel from 'react-owl-carousel2';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Keys from "../../../assets/images/additional/keys-gold.png";
import PropertyManagementUAEImage from "../../../assets/images/blogs/property-management-in-uae.png";
// import AnimatedLogo from "../../../assets/images/blue-logo-white-bg.gif";
//import Faq from "../../../assets/images/faq.png";

const schema = yup.object({
  email: yup.string().email().required("Email is a required field"),
  name: yup.string().required("Name is a required field"),
  public_key: yup.string().required("Public key is a required field"),
  amount: yup
    .number()
    .typeError("Amount is a required field")
    .positive("Amount must be a positive number"),
});
const BlogPropertyManagementInTheUAE = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [success, setSuccess] = useState(false);

  const onSubmit = async (data, event) => {
    event.preventDefault();
    API.post(process.env.REACT_APP_URL + "api/v1/contracts", data)
      .then((response) => {
        setSuccess(true);
        if (response) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Your form has been submitted.",
          });
          reset();
        }
      })
      .catch((error) => {
        if (error && error.response)
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error?.response?.data?.message,
          });
      });
  };
  const options = {
    items: 3,
    nav: false,
    dots:true,
    rewind: true,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    },
  };
  return (
    <>
      <Header />

        <section className="our-team">
          <div className="container">
          <div className="row">
            <div className="banner-size banner-content flex justify-content-center align-items-center m-auto">
              {/* <h1 className="white mb-4">Manage your Property & <span className="col-lg-10 bg-blu mb-2">Tenants</span> <span className="col-lg-10 bg-blu mb-2">without</span> the <span className="col-lg-10 bg-blu mb-2">Stress</span></h1> */}
              {/* <h4 className="col-lg-10 bg-blu mb-2">All the Tools you need to Manage Your Property Today!</h4> */}
              <h1 className="black mb-4 text-center">
                Managing your Property{" "}
                <span className="col-lg-10 bg-blu mb-2">and Porperty Porfolios </span>in{" "}
                <span className="col-lg-10 bg-blu mb-2">the U.A.E</span>
              </h1>

              <div className="mt-lg-5 d-flex align-items-center justify-content-center">
                <div className="theme-button">
                  <a href="https://app.sociallocket.com/register">
                    <strong>Sign Up Free</strong>
                  </a>
                </div>
              </div>
            </div>
          </div>
          </div>
        </section>

      <section className="about-us">
        <div className="container">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-lg-6 col-md-6">
                <div className="text-center mt-3">
                    <a href="https://app.sociallocket.com/register">
                        <img src={PropertyManagementUAEImage} className="img-fluid" alt="PropertyManagementUAEImage" />
                    </a>
                </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="connect-content mb-5">
                <h5 className="bg-txt-black  mb-4">Date - Jermaine Anderson</h5>

                    <p className="bg-txt-black  mb-4">
                    Are you a real estate agents, landlords and developers operating in the U.A.E? Have you been struggling to manage your property portfolios?
                    </p>

                    <p className="bg-txt-black  mb-4">
                    With the U.A.E embracing constantly change and development projects springing out of the wood works overnight, it’s important real estate agents, landlords and developers learn to adapt or suffer the consequences of been overtaken by the competition.
                    </p>

                    <p className="bg-txt-black  mb-4">
                    In wanting to see everyone win, <strong>Sociallocket</strong>  and its team have formed a plan to make life easier and better manage your properties and portfolios whilst on the go. 
                    </p>

                    <p className="bg-txt-black  mb-4">
                    <strong>Sociallocket</strong> understand the importance of property management with its founding team having years of experience in both investing and managing real estate assets.
                    </p>

                    <p className="bg-txt-black  mb-4">
                    In building an all-in-one solution, the <strong>Sociallocket</strong> platform is opening the doors to a better way of managing both small and large projects and property portfolios.                
                    </p>
                    <p className="bg-txt-black  mb-4">
                    With features consisting of:</p>

                    <p className="bg-txt-black  mb-4">
                        <li><strong>Free Property Advertisement</strong></li>
                        <li>Instant Chat (Both private and public)</li>
                        <li>Property Management</li>
                        <li>Customer Relationship Management</li>

                        <li>Portfolio Tracking</li>
                        <li>Payment Gateway (yes, that’s right! A payment gateway)</li>
                        <li>And many more</li>
                    </p>

                    <p className="bg-txt-black  mb-4">
                    <strong>Sociallocket</strong> is setting itself apart from the rest as we want to play our role in bringing back the joy into property investing, renting and management.</p>

                    <p className="bg-txt-black  mb-4">
                    If you’re a <strong>real estate agent</strong>, <strong>landlord</strong>, <strong>property developer</strong> (big or small) or someone looking for your new home, sign up to sociallocket.com today and get started. It’s <strong>FREE</strong> and <strong>EASY</strong>.
                    </p>

                <div className="mt-5">
                  <div className="theme-button">
                    <a href="https://app.sociallocket.com/register"><strong>Get Started</strong></a>
                  </div>
                </div>

                
              </div>
            </div>
            
          </div>
        </div>
      </section>

      <section className="our-team">
          <div className="container">
            <div className="row align-items-center flex-row-reverse">
            <div className="col-lg-6 col-md-6">
                <div className="text-center">
                  <a href="https://app.sociallocket.com/register">
                    <img src={Keys} className="img-fluid" alt="real-estate-property" />
                  </a>
                </div>
              </div>
              
              <div className="col-lg-6 col-md-6">
                <div className="connect-content mb-5">
                  <h2 className="white mb-4"><strong className="bg-txt-black mb-4">Earn</strong><span className="bg-blu"> additional income with your </span><strong className="bg-txt-black mb-4">Property</strong></h2>
                  <div className="mt-5">
                    <div className="theme-button">
                      <a href="https://app.sociallocket.com/register"><strong>Find out more</strong></a>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </section>  

      <Footer />
      {/* bootstrap jquery */}
      {/* bootstrap js */}
      {/* owl js */}
      {/* custom js */}
    </>
  );
};

export default BlogPropertyManagementInTheUAE;