import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Header from "../header-footer/header";
import Footer from "../header-footer/footer";
import blockchain_1 from "../../assets/images/blockchain_1.jpg";
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel2';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';

// import Aboutus from "../../assets/images/about-us.png";

import Aboutus from "../../assets/images/additional/for-rent-black.png";
import Search from "../../assets/images/additional/landlord-paycheck.png";

import LandlordHappy from "../../assets/images/additional/landlord-happy.png";
import EstateAgent from "../../assets/images/additional/estate-agents.png";
import Keys from "../../assets/images/additional/keys-gold.png";

import Blockchain from "../../assets/images/partners/future-blockchain-summit.jpeg";
import CryptoExpo from "../../assets/images/partners/crypto-expo.png";
import YahooFinance from "../../assets/images/partners/yahoo-finance.jpeg";
import CyberScope from "../../assets/images/partners/cyberscope.jpeg";
import Vincitore from "../../assets/images/partners/vincitore.png";
import Binghatti from "../../assets/images/partners/binghatti.jpeg";
import Danube from "../../assets/images/partners/danube.png";

const options = {
  items: 3,
  nav: false,
  dots:true,
  rewind: true,
  autoplay: true,
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    1024: {
      items: 3,
    },
  },
};

const AboutUs = () => {
    return (
      <div>
        <Header />
        <section className="our-team">
          <div className="container">
            <div className="row align-items-center flex-row-reverse">

              <div className="col-lg-6 col-md-6">
                <div className="text-center">
                  <a href="https://app.sociallocket.com/search">
                    <img src={Aboutus} className="img-fluid" alt="about-us" />
                  </a>
                </div>
              </div>
              
              <div className="col-lg-6 col-md-6">
                <div className="connect-content mb-5">
                  <h1 className="black mb-4"><strong>Advertise on <span className="bg-blu">Sociallocket</span></strong></h1>
                  <h4 className="black mb-4"><strong><span className="bg-blu">Advertise</span> your property on one of the fastest growing property portals, <span className="bg-blu">FOR FREE!</span></strong></h4>
                  <div className="mt-5">
                    <div className="theme-button">
                      <a href="https://app.sociallocket.com/register"><strong>Sign up Now</strong></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="about-us bg-blue">
          <div className="container">
            <div className="row align-items-center flex-row-reverse">
              <div className="col-lg-6 col-md-6">
                <div className="connect-content mb-5">
                  <h2 className="white mb-4"><strong className="bg-txt-black mb-4">One of the fastest growing</strong> <strong className="bg-txt-white mb-4">property portals</strong></h2>
                  <h4 className="bg-txt-black mb-4">
                  <strong>Sociallocket</strong> is changing the real estate market with a better, cheaper and more effective property portal.
                  </h4>
                  <h4 className="bg-txt-black mb-4">
                  <strong>Sociallocket</strong> provides all the tools needed for <strong className="white">tenants, landlords</strong> and <strong className="white">agents</strong> to obtain great value. With 'whatsapp' and 'shopify' like features, our team is here to support you!
                  </h4>
                  <h4 className="bg-txt-black mb-4">
                  <strong>Oh,</strong> and did we mention it's <strong className="white">FREE!</strong>
                  </h4>
                  <div className="mt-5">
                    <div className="theme-button">
                      <a href="https://app.sociallocket.com/search"><strong>Post Ad</strong></a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6">
                <div className="text-center">
                  <a href="https://app.sociallocket.com/register">
                    <img src={Search} className="img-fluid" alt="search" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
      
    );
  }
  
  export default AboutUs;