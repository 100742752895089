import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Header from "../header-footer/header";
import Footer from "../header-footer/footer";
import { useForm } from "react-hook-form";
import "../../css/style.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { API } from "../../services/ApiClient";
import Swal from "sweetalert2";
import OwlCarousel from 'react-owl-carousel2';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// import Demo from "../../assets/images/demo/demo-1.png";
// import Keys from "../../assets/images/additional/keys-gold.png";
// import Advertise from "../../assets/images/blogs/property-management-in-birmingham.png";

import Chat from "../../assets/images/services/24.png";
import Advertise from "../../assets/images/services/10.png";
import CRM from "../../assets/images/services/19.png";
import Search from "../../assets/images/services/25.png";
import Transactions from "../../assets/images/services/23.png";
import Reapit from "../../assets/images/services/26.png";
import Alto from "../../assets/images/services/27.png";
import Signable from "../../assets/images/services/28.png";
import SellAndRent from "../../assets/images/services/7.png";



const schema = yup.object({
  email: yup.string().email().required("Email is a required field"),
  name: yup.string().required("Name is a required field"),
  public_key: yup.string().required("Public key is a required field"),
  amount: yup
    .number()
    .typeError("Amount is a required field")
    .positive("Amount must be a positive number"),
});
const IntegrationsPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [success, setSuccess] = useState(false);

  const onSubmit = async (data, event) => {
    event.preventDefault();
    API.post(process.env.REACT_APP_URL + "api/v1/contracts", data)
      .then((response) => {
        setSuccess(true);
        if (response) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Your form has been submitted.",
          });
          reset();
        }
      })
      .catch((error) => {
        if (error && error.response)
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error?.response?.data?.message,
          });
      });
  };
  const options = {
    items: 3,
    nav: false,
    dots:true,
    rewind: true,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    },
  };
  return (
    <>
      <Header />
      <section className="blog-banner">
        <div className="container">
        <div className="row">
            <div className="banner-size banner-content flex justify-content-center align-items-center m-auto">
            <h1 className="black mb-4 text-center">
                {" "}
                <span className="col-lg-10 bg-blu mb-2">Advertise a room <span className="white">or property today</span></span>{" "}
                <span className="col-lg-10 bg-blu mb-2"></span>
              </h1>
              <h4 className="bg-blu text-center">And use a <span className="white">property portal</span> that <span className="white">makes life easy again</span></h4>
              <div className="mt-lg-5 d-flex align-items-center justify-content-center">
                <div className="theme-button">
                  <a href="https://app.sociallocket.com/register">
                    <strong>Sign Up Free</strong>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="about-us">
        <div className="container">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-lg-6 col-md-6">
              <div className="text-center">
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/YYwGN2OautA"></iframe>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="connect-content mb-5">
                <h2 className="mb-4">Sell your Property Today</h2>
                <p className="mb-4">
                See how <strong>Sociallocket</strong> can save you <strong>time</strong> and <strong>money</strong> whilst instilling <strong>trust</strong> and <strong>confidence!</strong>
                </p>
                <div className="mt-5">
                  <div className="theme-button">
                    <a href="https://calendly.com/sociallocket"><strong>How we can Help!</strong></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

        <section className="our-team">
          <div className="container">
            <div className="row align-items-center flex-row-reverse">
            <div className="col-lg-6 col-md-6">
                <div className="text-center">
                  <a href="https://app.sociallocket.com/search">
                    <img src={Search} className="img-fluid" alt="real-estate-property" />
                  </a>
                </div>
              </div>
              
              <div className="col-lg-6 col-md-6">
                <div className="connect-content mb-5">
                <h2 className="black mb-4">Integrate directly with your <strong className="bg-blu mb-4">Apex 27</strong> account and start advertising your <span className="bg-blu">properties</span><strong className="bg-txt-black mb-4"></strong></h2>
                <div className="mt-5">
                    <div className="theme-button">
                      <a href="https://app.sociallocket.com/register"><strong>Sign Up Free</strong></a>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </section> 

        <section className="our-team">
          <div className="container">
            <div className="row align-items-center flex-row-reverse">
            <div className="col-lg-6 col-md-6">
                <div className="text-center">
                  <a href="https://app.sociallocket.com/register">
                    <img src={Reapit} className="img-fluid" alt="real-estate-property" />
                  </a>
                </div>
              </div>
              
              <div className="col-lg-6 col-md-6">
                <div className="connect-content mb-5">
                <h2 className="black mb-4">Integrate directly with your <strong className="bg-blu mb-4">Reapit</strong> account and start advertising your <span className="bg-blu">properties</span><strong className="bg-txt-black mb-4"></strong></h2>
                  <div className="mt-5">
                    <div className="theme-button">
                      <a href="https://app.sociallocket.com/register"><strong>Get Started Today</strong></a>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </section>   

        <section className="our-team">
          <div className="container">
            <div className="row align-items-center flex-row-reverse">
            <div className="col-lg-6 col-md-6">
                <div className="text-center">
                  <a href="https://app.sociallocket.com/register">
                    <img src={Chat} className="img-fluid" alt="real-estate-property" />
                  </a>
                </div>
              </div>
              
              <div className="col-lg-6 col-md-6">
                <div className="connect-content mb-5">
                <h2 className="black mb-4">Integrate directly with your <strong className="bg-blu mb-4">10ninety</strong> account and start advertising your <span className="bg-blu">properties</span><strong className="bg-txt-black mb-4"></strong></h2>
                  <div className="mt-5">
                    <div className="theme-button">
                      <a href="https://app.sociallocket.com/register"><strong>Get Started Today</strong></a>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </section> 


      <section className="our-team">
          <div className="container">
            <div className="row align-items-center flex-row-reverse">
            <div className="col-lg-6 col-md-6">
                <div className="text-center">
                  <a href="https://app.sociallocket.com/register">
                    <img src={Transactions} className="img-fluid" alt="real-estate-property" />
                  </a>
                </div>
              </div>
              
              <div className="col-lg-6 col-md-6">
                <div className="connect-content mb-5">
                <h2 className="black mb-4">Integrate directly with your <strong className="bg-blu mb-4">Jupix</strong> account and start advertising your <span className="bg-blu">properties</span><strong className="bg-txt-black mb-4"></strong></h2>
                  <div className="mt-5">
                    <div className="theme-button">
                      <a href="https://app.sociallocket.com/register"><strong>Free Sign Up</strong></a>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </section> 

        <section className="our-team">
          <div className="container">
            <div className="row align-items-center flex-row-reverse">
            <div className="col-lg-6 col-md-6">
                <div className="text-center">
                  <a href="https://app.sociallocket.com/register">
                    <img src={Alto} className="img-fluid" alt="real-estate-property" />
                  </a>
                </div>
              </div>
              
              <div className="col-lg-6 col-md-6">
                <div className="connect-content mb-5">
                <h2 className="black mb-4">Integrate directly with your <strong className="bg-blu mb-4">Alto</strong> account and start advertising your <span className="bg-blu">properties</span><strong className="bg-txt-black mb-4"></strong></h2>
                  <div className="mt-5">
                    <div className="theme-button">
                      <a href="https://app.sociallocket.com/register"><strong>Get Started Today</strong></a>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </section>  

        <section className="our-team">
          <div className="container">
            <div className="row align-items-center flex-row-reverse">
            <div className="col-lg-6 col-md-6">
                <div className="text-center">
                  <a href="https://app.sociallocket.com/register">
                    <img src={Signable} className="img-fluid" alt="real-estate-property" />
                  </a>
                </div>
              </div>
              
              <div className="col-lg-6 col-md-6">
                <div className="connect-content mb-5">
                <h2 className="black mb-4">Integrate directly with your <strong className="bg-blu mb-4">Signable</strong> account and start signing documents and tenancy agreements <span className="bg-blu">today</span><strong className="bg-txt-black mb-4"></strong></h2>
                  <div className="mt-5">
                    <div className="theme-button">
                      <a href="https://app.sociallocket.com/register"><strong>Get Started Today</strong></a>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </section>  

        <section className="our-team">
          <div className="container">
            <div className="row align-items-center flex-row-reverse">
            <div className="col-lg-6 col-md-6">
                <div className="text-center">
                  <a href="https://app.sociallocket.com/register">
                    <img src={CRM} className="img-fluid" alt="real-estate-property" />
                  </a>
                </div>
              </div>
              
              <div className="col-lg-6 col-md-6">
                <div className="connect-content mb-5">
                  <h2 className="black mb-4">Integrate directly with your <strong className="bg-blu mb-4">real estate crms</strong> and start advertising your <span className="bg-blu">properties</span><strong className="bg-txt-black mb-4"></strong></h2>
                  <div className="mt-5">
                    <div className="theme-button">
                      <a href="https://app.sociallocket.com/register"><strong>Create an Account</strong></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="our-team">
          <div className="container">
            <div className="row align-items-center flex-row-reverse">
            <div className="col-lg-6 col-md-6">
                <div className="text-center">
                  <a href="https://app.sociallocket.com/register">
                    <img src={Advertise} className="img-fluid" alt="real-estate-property" />
                  </a>
                </div>
              </div>
              
              <div className="col-lg-6 col-md-6">
                <div className="connect-content mb-5">
                <h2 className="black mb-4">Integrate directly with your <strong className="bg-blu mb-4">real estate database</strong> and start advertising your <span className="bg-blu">properties</span><strong className="bg-txt-black mb-4"></strong></h2>
                <div className="mt-5">
                    <div className="theme-button">
                      <a href="https://app.sociallocket.com/register"><strong>Create Free Account</strong></a>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </section>
      <Footer />
      {/* bootstrap jquery */}
      {/* bootstrap js */}
      {/* owl js */}
      {/* custom js */}
    </>
  );
};

export default IntegrationsPage  ;