import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Header from "./header-footer/header";
import Footer from "./header-footer/footer";
import Demo from "../assets/images/demo/demo-1.png";

const DemoVideo = () => {
    return (
      <div>
        <Header />
        
      <section className="about-us">
        <div className="container">
          <div className="row align-items-center flex-row-reverse">
            {/* <div className="col-lg-6 col-md-6">
              <div className="text-center">
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/YYwGN2OautA"></iframe>
              </div>
            </div> */}
            <div className="col-lg-6 col-md-6">
              <div className="text-center">
                <a href="https://calendly.com/sociallocket">
                  <img src={Demo} className="img-fluid" alt="demo"/>
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="connect-content mb-5">
                {/* <h2 className="bg-txt-black mb-4">Sell your Property Today</h2> */}
                <h2 className="bg-txt-black mb-4">Book a Demo</h2>
                <h4 className="bg-txt-black mb-4">
                See how <strong >Sociallocket</strong> can save you <strong>time</strong> and <strong>money</strong> whilst instilling <strong>trust</strong> and <strong>confidence!</strong>
                </h4>
                <div className="mt-5">
                  <div className="theme-button">
                    <a href="https://calendly.com/sociallocket"><strong>Book A Demo</strong></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      </div>
      
    );
  }
  
  export default DemoVideo;