import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Header from "../../header-footer/header";
import Footer from "../../header-footer/footer";
import { useForm } from "react-hook-form";
import "../../../css/style.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { API } from "../../../services/ApiClient";
import Swal from "sweetalert2";
import OwlCarousel from 'react-owl-carousel2';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import FractionalisationImage from "../../../assets/images/fractionalisation.png";
import AnimatedLogo from "../../../assets/images/blue-logo-white-bg.gif";
//import Faq from "../../../assets/images/faq.png";

const schema = yup.object({
  email: yup.string().email().required("Email is a required field"),
  name: yup.string().required("Name is a required field"),
  public_key: yup.string().required("Public key is a required field"),
  amount: yup
    .number()
    .typeError("Amount is a required field")
    .positive("Amount must be a positive number"),
});
const Landing = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [success, setSuccess] = useState(false);

  const onSubmit = async (data, event) => {
    event.preventDefault();
    API.post(process.env.REACT_APP_URL + "api/v1/contracts", data)
      .then((response) => {
        setSuccess(true);
        if (response) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Your form has been submitted.",
          });
          reset();
        }
      })
      .catch((error) => {
        if (error && error.response)
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error?.response?.data?.message,
          });
      });
  };
  const options = {
    items: 3,
    nav: false,
    dots:true,
    rewind: true,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    },
  };
  return (
    <>
      <Header />
      <section className="banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 banner-content">
              <h1 className="white mb-4">The Power Of Fractionalised Assets</h1>
              <h4 className="bg-blu mb-2">Why Wait? Start Your Investment Journey Today!</h4>
              <p className="white mb-4 col-lg-8">
               With <strong>Sociallocket</strong>, you can invest in real estate assets from as little as <strong>1%</strong> to <strong>100%</strong>. You now have full control of how, where and when you <strong>invest</strong>.
              </p>
              <div className="mt-lg-5">
                <div className="theme-button">
                  <a href="https://app.sociallocket.com/register"><strong>1% to 100% Ownership. Start Investing Today</strong></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-us">
        <div className="container">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-lg-6 col-md-6">
              <div className="text-center mt-3">
                <img src={FractionalisationImage} className="img-fluid" alt="FractionalisationImage" />
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="connect-content mb-5">
                <h2 className="bg-txt-black  mb-4">Fractionalised Assets</h2>
                <h4 className="bg-txt-black  mb-4">
                  With things such as artificial intelligence becoming a hot topic, fractionisation is changing the way we now invest in real estate assets. 
                </h4>
                <h4 className="bg-txt-black  mb-4">
                  This has opened the doors to many, causing real estate to no longer be the asset class that is only for the wealthy but instead for everyone. 
                </h4>
                <h4 className="bg-txt-black  mb-4">
                  Introducing fractionalised assets will help propel many into positions they had only once dreamed of. 
                  Allowing people to invest small amounts at a time, fractionalised assets will give people the opportunity to begin and grow their real estate portfolio as part of generating generational wealth. 
                  So, no more worrying about how you’re going to invest and more about what and where you are going to invest. Worry no more though, Sociallocket will help!
                </h4>
                <div className="mt-5">
                  <div className="theme-button">
                    <a href="https://app.sociallocket.com/register"><strong>Are You Ready to Invest?</strong></a>
                  </div>
                </div>

                
              </div>
            </div>
            
          </div>
        </div>
      </section>



      <Footer />
      {/* bootstrap jquery */}
      {/* bootstrap js */}
      {/* owl js */}
      {/* custom js */}
    </>
  );
};

export default Landing;