import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Header from "../../header-footer/header";
import Footer from "../../header-footer/footer";
import { useForm } from "react-hook-form";
import "../../../css/style.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { API } from "../../../services/ApiClient";
import Swal from "sweetalert2";
import OwlCarousel from 'react-owl-carousel2';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import ZooplaAltImage from "../../../assets/images/blogs/zoopla-alt.png";
import Keys from "../../../assets/images/additional/keys-gold.png";
import InflationImage from "../../../assets/images/inflation.png";
// import AnimatedLogo from "../../../assets/images/blue-logo-white-bg.gif";
//import Faq from "../../../assets/images/faq.png";

const schema = yup.object({
  email: yup.string().email().required("Email is a required field"),
  name: yup.string().required("Name is a required field"),
  public_key: yup.string().required("Public key is a required field"),
  amount: yup
    .number()
    .typeError("Amount is a required field")
    .positive("Amount must be a positive number"),
});
const BlogAlternativeToZoopla = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [success, setSuccess] = useState(false);

  const onSubmit = async (data, event) => {
    event.preventDefault();
    API.post(process.env.REACT_APP_URL + "api/v1/contracts", data)
      .then((response) => {
        setSuccess(true);
        if (response) {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Your form has been submitted.",
          });
          reset();
        }
      })
      .catch((error) => {
        if (error && error.response)
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error?.response?.data?.message,
          });
      });
  };
  const options = {
    items: 3,
    nav: false,
    dots:true,
    rewind: true,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    },
  };
  return (
    <>
      <Header />

        <section className="our-team">
          <div className="container">
          <div className="row">
            <div className="banner-size banner-content flex justify-content-center align-items-center m-auto">
              {/* <h1 className="white mb-4">Manage your Property & <span className="col-lg-10 bg-blu mb-2">Tenants</span> <span className="col-lg-10 bg-blu mb-2">without</span> the <span className="col-lg-10 bg-blu mb-2">Stress</span></h1> */}
              {/* <h4 className="col-lg-10 bg-blu mb-2">All the Tools you need to Manage Your Property Today!</h4> */}
              <h1 className="black mb-4 text-center">
                Is Sociallocket an{" "}
                <span className="col-lg-10 bg-blu mb-2">Alternative </span>to{" "}
                <span className="col-lg-10 bg-blu mb-2">Zoopla</span>
              </h1>

              <div className="mt-lg-5 d-flex align-items-center justify-content-center">
                <div className="theme-button">
                  <a href="https://app.sociallocket.com/register">
                    <strong>Sign Up Free</strong>
                  </a>
                </div>
              </div>
            </div>
          </div>
          </div>
        </section>

      <section className="about-us">
        <div className="container">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-lg-6 col-md-6">
              <div className="text-center mt-3">
                <a href="https://app.sociallocket.com/register">
                  <img src={ZooplaAltImage} className="img-fluid" alt="ZooplaAltImage" />
                </a>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="connect-content mb-5 ">
                <h5 className="bg-txt-black  mb-4">June 4, 2024 - Jermaine Anderson</h5>

                <p className="bg-txt-black  mb-4">
                In Zoopla’s own words “We're Zoopla. A property website and app.” With this not far from the truth, others have referred to Zoopla as “UK’s most comprehensive property website”.
                </p>

                <p className="bg-txt-black  mb-4">
                With such statements having a strong leg to stand on, others have raged with anger and frustration and slandered Zoopla as unreliable, unstable and outdated.
                </p>

                <p className="bg-txt-black  mb-4">
                Such statements have derived from Zoopla’s track record of causing there users pain with  issues such as:
                </p>

                <p className="bg-txt-black  mb-4">
                  <li>High subscription pricing</li>
                  <li>Software Outages</li>
                  <li>Users not being able to sign in</li>
                  <li>Reported problems with their map (Sociallocket has also experienced these problems so please take it easy on them. Oh, and us.)</li>

                  <li>Missing content and or issues updating content</li>
                  <li>Unreliable valuations</li>
                  <li>Outdated listings (nothing to identify whether the property has been let or sold)</li>
                </p>


                <p className="bg-txt-black  mb-4">
                With that said, joy is here as we’ve been building and will continue to build a better alternative. What’s that you may ask? Sociallocket!
                </p>

                <p className="bg-txt-black  mb-4">
                Striving to make life easier, Sociallocket is a unique property portal designed to streamline the acquisitions and rentals of real estate assets (Apartments, Houses, Bungalows, Commercial Buildings, Rooms and many more).
                Sociallocket is designed to connect renters (tenants) with real estate agents and landlords as part of bringing the joy back into our homes.
                </p>

                <p className="bg-txt-black  mb-4">
                With WhatsApp like features and a Shopify style approach, Sociallocket is paving the way and removing stress from property investing and renting.
                </p>

                <p className="bg-txt-black  mb-4">
                We are far from getting started but we take pride in the Sociallocket platform as it’s making things easier for agents, landlords, renters and even real estate (property) developers by providing the tools and apps they need.
                </p>

                <p className="bg-txt-black  mb-4">
                  <li>Free Property Advertisement (self-serve)</li>
                  <li>Instant Chat (Both private and public)</li>
                  <li>Property Management</li>
                  <li>Customer Relationship Management</li>

                  <li>Portfolio Tracking</li>
                  <li>Payment Gateway (yes, that’s right! A payment gateway)</li>
                  <li>And many more</li>
                </p>

                <p className="bg-txt-black  mb-4">
                Now do you see why we’re a better alternative? Minus the traffic, daily active users and number of listings Sociallocket has in comparison to Zoopla, Sociallocket is on the rise and growing rapidly.
                </p>

                <p className="bg-txt-black  mb-4">
                If you’re a real estate agent, landlord, property developer (big or small) or someone looking for your new home, sign up to sociallocket.com today and get started. It’s FREE and EASY
                </p>

                <div className="mt-5">
                  <div className="theme-button">
                    <a href="https://app.sociallocket.com/register"><strong>Get Started</strong></a>
                  </div>
                </div>

                
              </div>
            </div>
            
          </div>
        </div>
      </section>

      <section className="our-team">
          <div className="container">
            <div className="row align-items-center flex-row-reverse">
            <div className="col-lg-6 col-md-6">
                <div className="text-center">
                  <a href="https://app.sociallocket.com/register">
                    <img src={Keys} className="img-fluid" alt="real-estate-property" />
                  </a>
                </div>
              </div>
              
              <div className="col-lg-6 col-md-6">
                <div className="connect-content mb-5">
                  <h2 className="white mb-4"><strong className="bg-txt-black mb-4">Earn</strong><span className="bg-blu"> additional income with your </span><strong className="bg-txt-black mb-4">Property</strong></h2>
                  <div className="mt-5">
                    <div className="theme-button">
                      <a href="https://app.sociallocket.com/register"><strong>Find out more</strong></a>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </section>  

      <Footer />
      {/* bootstrap jquery */}
      {/* bootstrap js */}
      {/* owl js */}
      {/* custom js */}
    </>
  );
};

export default BlogAlternativeToZoopla;